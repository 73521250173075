<template>
  <section
    v-if="status != ''"
    id="client-section"
  >
    <div
      :class="backdrop"
      style="z-index:1032;"
    />
    <div v-if="userRole === 'admin' || userRole === 'agent'">
      <b-col
        cols="4"
      >
        <transition name="fade">
          <add-tasks
            v-if="editForm === true"
            id="addTaskForm"
            :shift-id="shiftId"
            :caregiver-id="caregiverId"
            :clock-id="clockId"
            :shift-data="shiftData"
            :form-title="formTitle"
            :task-id="taskId"
            :date-filter="dateFilter"
            :task-data="taskData"
            :task-details="taskDetails"
          />
        </transition>
      </b-col>
      <b-col
        cols="4"
      >
        <transition name="fade">
          <add-clients
            v-if="addForm === true"
            id="addClientForm"
            form-title="Update Client"
            :client-details="clientDetails"
            :client-id="id"
          />
        </transition>
      </b-col>
      <client-tabs
        :user-role="userRole"
      />
      <b-row class="match-height">
        <!-- CLIENT SECTION -->
        <b-col
          :lg="familyMember.length > 0 ? '9' : '12'"
          cols="12"
        >
          <b-card no-body>
            <b-card-body>
              <b-row>
                <b-col
                  lg="6"
                  cols="12"
                >
                  <div class="clearfix">
                    <b-img
                      left
                      class="mb-1 mb-sm-0 pr-1"
                    >
                      <b-avatar
                        text="client"
                        variant="primary"
                      />
                    </b-img>
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          :src="`${urlUpload}clients/${profile_picture}`"
                          :text="avatarText(client)"
                          size="104px"
                          variant="primary"
                          class="mr-1 text-center"
                          rounded
                        />
                        <!-- <b-avatar
                          v-if="profile_picture === ''"
                          class="mr-1 text-center"
                          size="100"
                          variant="primary"
                          rounded
                          :src="clientDetails[0].profile_picture"
                          :text="avatarText(client)"
                        /> -->
                        <!-- <b-img
                          v-if="profile_picture !== ''"
                          class="mb-1 mb-sm-0 mr-1"
                          :src="`${urlUpload}clients/${profile_picture}`"
                          height="100"
                          width="100"
                          rounded
                        /> -->
                        <!-- <b-avatar
                          v-if="profile_picture !== ''"
                          class="mb-1 mb-sm-0 mr-1"
                          variant="primary"
                          :src="`${urlUpload}clients/${profile_picture}`"
                          size="100"
                          rounded
                        /> -->
                        <span>
                          <h3
                            id="client-name"
                            class=""
                          >
                            {{ client }}
                          </h3>
                          <p class="text-primary">
                            {{ emailAddress }}
                          </p>
                          <b-button
                            v-if="status == 'active' && userRole === 'admin'"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="secondary"
                            class="font-weight-normal mr-1"
                            @click="goToEditProfile"
                          >
                            Edit
                          </b-button>
                          <b-button
                            v-if="status == 'active' && userRole === 'admin'"
                            id="toggle-btn"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-b-modal.deactivateModal
                            variant="outline-danger"
                            class="font-weight-normal"
                          >
                            Deactivate
                          </b-button>
                          <b-button
                            v-if="status == 'inactive'"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="secondary"
                            class="mr-1"
                            @click="showMsgBoxOne"
                          >
                            Activate
                          </b-button>
                        </span>
                      </template>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  lg="6"
                  cols="12"
                >
                  <p class=" font-weight-bolder gray-1">
                    <feather-icon
                      icon="ActivityIcon"
                      size="16"
                    />
                    Status: <span class=" font-weight-normal ml-1 text-capitalize black"> {{ status || 'Not Available' }} </span>
                  </p>
                  <p class=" font-weight-bolder gray-1">
                    <feather-icon
                      icon="HomeIcon"
                      size="16"
                    />
                    Address: <span class=" font-weight-normal ml-1 black"> {{ address || 'Not Available' }} </span>
                  </p>
                  <p class=" font-weight-bolder gray-1">
                    <feather-icon
                      icon="PhoneIcon"
                      size="16"
                    />
                    Home: <span class=" font-weight-normal ml-1 black"> {{ home || 'Not Available' }} </span>
                  </p>
                  <p class=" font-weight-bolder gray-1">
                    <feather-icon
                      icon="MapPinIcon"
                      size="16"
                    />
                    Location: <span class=" font-weight-normal ml-1 black"> {{ location || 'Not Available' }} </span>
                  </p>
                  <p
                    v-if="age"
                    class=" font-weight-bolder gray-1"
                  >
                    <feather-icon
                      icon="UserIcon"
                      size="16"
                    />
                    Age: <span class=" font-weight-normal ml-1 black"> {{ age || 'Not Available' }} </span>
                  </p>
                  <p class=" font-weight-bolder gray-1">
                    <feather-icon
                      icon="KeyIcon"
                      class=""
                      size="16"
                    />
                    Referrer: <span class=" font-weight-normal ml-1 text-capitalize black"> {{ referrer || 'Not Available' }} </span>
                  </p>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <!-- Family Section -->
        <b-col
          v-if="familyMember.length > 0"
          :class="familySection"
          lg="3"
          cols="12"
        >
          <b-card
            no-body
            class="family-border"
          >
            <b-card-body>
              <h5
                class="float-right text-primary mt-0"
              >
                Family
              </h5>
              <b-tabs
                small
                pills
                nav-class="mb-1 family-section"
              >
                <b-tab
                  v-for="(value, index) in familyMember"
                  :key="index"
                  :title="value.id + ' ' + (value.id === 'PIC' ? (index+1) : '')"
                  active
                >
                  <h5>
                    {{ value.name }}
                  </h5>
                  <p class="font-weight-bolder">
                    <feather-icon
                      icon="PhoneIcon"
                      class="gray-1 mr-2"
                      size="16"
                    />
                    <span class="font-weight-normal"> H: {{ value.homephone }}</span>
                  </p>
                  <p class="font-weight-bolder">
                    <feather-icon
                      icon="MailIcon"
                      class="gray-1 mr-2"
                      size="16"
                    />
                    <span class="font-weight-normal text-primary"> {{ value.email }} </span>
                  </p>
                  <p class="font-weight-bolder">
                    <feather-icon
                      icon="UsersIcon"
                      class="gray-1 mr-2"
                      size="16"
                    />
                    <span class="font-weight-normal"> {{ value.relationship }} </span>
                  </p>
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- DAILY CARE LOGS -->
      <div
        v-if="show === true && status === 'active' && userRole === 'admin'"
        class="accordion"
        role="tablist"
      >
        <b-card
          no-body
          class="mb-1 client-accordion"
        >
          <b-card-header
            header-tag="header"
            class="p-0"
            role="tab"
          >
            <b-col
              v-b-toggle.accordion-1
              lg="12"
              cols="12"
              class="pt-1 pb-1 border-bottom"
              @click="carelogsChevron == 'ChevronDownIcon' ? carelogsChevron = 'ChevronUpIcon' : carelogsChevron = 'ChevronDownIcon'"
            >
              <h5 class="">
                Daily Care Logs
                <feather-icon
                  :icon="carelogsChevron"
                  class="float-right"
                />
              </h5>
            </b-col>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            accordion="my-accordion"
            role="tabpanel"
            visible
          >
            <b-card-body>
              <b-row>
                <b-col
                  lg="6"
                  cols="12"
                >
                  <h5 class="">{{ weekdayName }}, {{ monthName }} {{ day }}
                    <span>
                      <b-button
                        class="btn-calendar ml-1 p-1"
                        variant="outline-primary"
                        size="40"
                        @click="triggerCalendar"
                      >
                        <feather-icon
                          icon="CalendarIcon"
                          size="15"
                        />
                      </b-button>
                      <flat-pickr
                        id="due-date"
                        ref="mycalendar"
                        v-model="dateFilter"
                        class="invisible "
                        :value="dateFilter"
                        :config="flatPickrConfig"
                        placeholder="Choose a date"
                        @input="getTasks(dateFilter)"
                      />
                    </span>
                  </h5>
                </b-col>
                <b-col
                  v-if="servicesPlan.length !== 0"
                  lg="6"
                  cols="12"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.expenseModal
                    class="ml-0 float-right mr-1"
                    variant="secondary"
                  >
                    + Expense
                  </b-button>
                </b-col>
                <b-col
                  lg="12"
                  cols="12"
                  class="mb-1"
                >
                  <div
                    class="accordion"
                  >
                    <b-card
                      v-for="(value, index) in servicesPlan"
                      :key="index"
                      no-body
                      class="mb-1 border"
                      role="tablist"
                    >
                      <b-card-header
                        header-tag="header"
                        class="p-0 task-accordion"
                        role="tab"
                        @click="getClockId(servicesPlan[servicesPlan[index][0].caregiver_id])"
                      >
                        <b-col
                          v-b-toggle="'task-'+servicesPlan[index][0].id"
                          lg="12"
                          cols="12"
                          class="pt-1 pb-1"
                          @click="taskChevron == 'ChevronDownIcon' ? taskChevron = 'ChevronUpIcon' : taskChevron = 'ChevronDownIcon'"
                        >
                          <h5>
                            <i
                              v-if="servicesPlan[index][0].shift_status === 'Completed' || servicesPlan[index][0].shift_status === 'completed'"
                              id="taks-check-icon"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="18"
                                class="check-circle-icon"
                              />
                            </i>
                            <i
                              v-if="servicesPlan[index][0].shift_status === 'Scheduled' || servicesPlan[index][0].shift_status === 'scheduled'"
                              id="taks-watch-icon"
                            >
                              <feather-icon
                                icon="WatchIcon"
                                size="18"
                                class="watch-circle-icon"
                              />
                            </i>
                            <i
                              v-if="servicesPlan[index][0].shift_status === 'In Progress' || servicesPlan[index][0].shift_status === 'in progress'"
                              id="taks-watch-icon-inprogress"
                            >
                              <feather-icon
                                icon="WatchIcon"
                                size="18"
                                class="watch-circle-icon"
                              />
                            </i>
                            <span
                              id="task-caregiver"
                              class="ml-1"
                            >
                              {{ servicesPlan[index][0].time }} {{ servicesPlan[index][0].caregiver }} ({{ servicesPlan[index][0].shift_status }})
                            </span>
                            <feather-icon
                              :icon="taskChevron"
                              class="float-right"
                            />
                          </h5>
                        </b-col>
                      </b-card-header>
                      <b-collapse
                        :id="'task-'+servicesPlan[index][0].id"
                        accordion="task-accordion"
                        role="tabpanel"
                      >
                        <b-card-body class="pl-0 pr-0">
                          <b-row class="show ml-0 mr-1">
                            <b-col
                              lg="6"
                              cols="12"
                            >
                              <h4>Task List
                                <span>
                                  <b-button
                                    v-if="userRole === 'admin'"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    class="ml-2 mr-1"
                                    variant="secondary"
                                    size="sm"
                                    @click="addTask(servicesPlan[index][servicesPlan[index].length-1].id,servicesPlan[index][0].caregiver_id)"
                                  >
                                    + Add Task
                                  </b-button>
                                </span>
                              </h4>
                            </b-col>
                            <b-col
                              lg="6"
                              cols="12"
                            >
                              <p class="float-right data-from">
                                <feather-icon
                                  icon="ClockIcon"
                                  size="14"
                                  class="text-body align-middle mr-25"
                                />
                                From {{ start_date }} {{ servicesPlan[index][0].start_time }} to {{ end_date }} {{ servicesPlan[index][servicesPlan[index].length-1].end_time }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row class="show">
                            <b-col
                              lg="12"
                              cols="12"
                              class="pl-0 pr-0"
                            >
                              <b-table
                                :fields="fields"
                                :items="servicesPlan[servicesPlan[index][0].caregiver_id]"
                                show-empty
                                empty-text="No matching records found"
                                responsive
                                class=" section-border mb-0"
                              >
                                <template #cell(status)="columndata">
                                  <b-media vertical-align="center">
                                    <feather-icon
                                      v-if="columndata.item.status === 'Completed' || columndata.item.status === 'completed'"
                                      icon="CheckIcon"
                                      size="16"
                                      class="text-body align-middle mr-25"
                                    />
                                    <feather-icon
                                      v-if="columndata.item.status === 'Not-Completed' || columndata.item.status === 'not-completed'"
                                      icon="XIcon"
                                      size="16"
                                      class="text-body align-middle mr-25"
                                    />
                                    <span
                                      v-if="columndata.item.status === 'Completed' || columndata.item.status === 'completed'"
                                      class=""
                                    >
                                      Completed
                                    </span>
                                    <span
                                      v-if="columndata.item.status === 'Not-Completed' || columndata.item.status === 'not-completed'"
                                      class=""
                                    >
                                      Not-Completed
                                    </span>
                                  </b-media>
                                </template>
                                <template #cell(time)="columndata">
                                  <p
                                    class="text-lowercase "
                                  >
                                    {{ columndata.item.time }}
                                  </p>
                                </template>
                                <template #cell(actions)="columndata">
                                  <span>
                                    <b-dropdown
                                      variant="link"
                                      toggle-class="text-decoration-none"
                                      no-caret
                                    >
                                      <template v-slot:button-content>
                                        <feather-icon
                                          icon="MoreVerticalIcon"
                                          size="16"
                                          class="text-body align-middle mr-25"
                                        />
                                      </template>
                                      <b-dropdown-item
                                        :id="columndata.item.id"
                                        @click="editTask(columndata.item.task_id,columndata.item.task,columndata.item.clockId,columndata.item)"
                                      >
                                        <span>Edit</span>
                                      </b-dropdown-item>
                                      <b-dropdown-item
                                        v-if="columndata.item.image"
                                        :id="columndata.item.id"
                                        v-b-modal.viewPhotos
                                        @click="viewPhotos(columndata.item.image)"
                                      >
                                        <span>View Photos</span>
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </span>
                                </template>
                              </b-table>
                            </b-col>
                          </b-row>
                          <b-row class="">
                            <b-col
                              cols="12"
                              lg="12"
                            >
                              <b-card-group
                                deck
                                class="pl-2 pr-2 pb-2 pt-2"
                              >
                                <b-card
                                  v-if="servicesPlan[servicesPlan[index][0].caregiver_id][0].sign_client == 1 && signedClient[servicesPlan[index][0].caregiver_id]"
                                  bg-variant="primary"
                                  text-variant="dark"
                                  class="card-signature pb-2"
                                  rounded
                                >
                                  <b-row>
                                    <b-col
                                      lg="6"
                                      cols="12"
                                      class="pt-2 pb-2"
                                    >
                                      <h5
                                        class=""
                                      >
                                        Client Signature
                                      </h5>
                                    </b-col>
                                    <b-col
                                      lg="6"
                                      cols="12"
                                      class="pt-1 pl-2"
                                    >
                                      <b-button
                                        class="float-right"
                                        variant="outline-danger"
                                        @click.prevent="deleteClientSignature(clockId)"
                                      >
                                        Delete
                                      </b-button>
                                    </b-col>
                                    <b-col
                                      cols="12"
                                      lg="12"
                                    >
                                      <b-img
                                        class="daily-care-signature"
                                        :src="`${mainUrl}api/mobile/signature/sig-client-${servicesPlan[index][0].clockId}.jpg`"
                                        fluid
                                        height="150"
                                        width="250"
                                        center
                                        alt="Responsive image"
                                      />
                                      <b-card-text
                                        class="pull-left pt-2"
                                      >
                                        Signed - Electric Signature
                                      </b-card-text>
                                      <b-card-text
                                        v-if="signedClient[servicesPlan[index][0].caregiver_id]"
                                        class="pull-left"
                                      >
                                        {{ signedClient[servicesPlan[index][0].caregiver_id][0] }}
                                      </b-card-text>
                                    </b-col>
                                  </b-row>
                                </b-card>
                                <b-card
                                  v-if="servicesPlan[servicesPlan[index][0].caregiver_id][0].sign_caregiver == 1 && signedCaregiver[servicesPlan[index][0].caregiver_id]"
                                  bg-variant="primary"
                                  text-variant="dark"
                                  class="card-signature pb-2"
                                  rounded
                                >
                                  <b-row>
                                    <b-col
                                      lg="6"
                                      cols="12"
                                      class="pt-2 pb-2"
                                    >
                                      <h5
                                        class=""
                                      >
                                        Caregiver Signature
                                      </h5>
                                    </b-col>
                                    <b-col
                                      lg="6"
                                      cols="12"
                                      class="pt-1 pl-2"
                                    >
                                      <b-button
                                        class="float-right"
                                        variant="outline-danger"
                                        @click.prevent="deleteCaregiverSignature(clockId)"
                                      >
                                        Delete
                                      </b-button>
                                    </b-col>
                                    <b-col
                                      cols="12"
                                      lg="12"
                                    >
                                      <b-img
                                        class="daily-care-signature"
                                        :src="`${mainUrl}api/mobile/signature/sig-caregiver-${servicesPlan[index][0].clockId}.jpg`"
                                        height="150"
                                        width="250"
                                        fluid
                                        center
                                        alt="Responsive image"
                                      />
                                      <b-card-text
                                        class="pull-left pt-2"
                                      >
                                        Signed - Electric Signature
                                      </b-card-text>
                                      <b-card-text
                                        v-if="signedCaregiver[servicesPlan[index][0].caregiver_id]"
                                        class="pull-left"
                                      >
                                        {{ signedCaregiver[servicesPlan[index][0].caregiver_id][0] }}
                                      </b-card-text>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </b-card-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <!-- SHIFT CALENDAR -->
      <div
        v-if="show === true && status === 'active' && userRole === 'admin'"
        class="accordion2"
        role="tablist"
      >
        <b-card
          no-body
          class="mb-1 client-accordion pb-0"
        >
          <b-card-header
            header-tag="header"
            class="p-0"
            role="tab"
          >
            <b-col
              v-b-toggle.accordion-2
              lg="12"
              cols="12"
              class="pt-1 pb-1 border-bottom"
              @click="calendarChevron == 'ChevronDownIcon' ? calendarChevron = 'ChevronUpIcon' : calendarChevron = 'ChevronDownIcon'"
            >
              <h5 class="">
                Shift Calendar
                <feather-icon
                  :icon="calendarChevron"
                  class="float-right"
                />
              </h5>
            </b-col>
          </b-card-header>
          <b-collapse
            id="accordion-2"
            visible
            accordion="my-accordion2"
            role="tabpanel"
          >
            <b-card-body class="pb-0 pl-1 pr-1">
              <b-row>
                <b-col
                  lg="12"
                  cols="12"
                  class="mb-0 p-0"
                >
                  <client-calendar
                    class="mb-0  mt-0"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <b-img
        v-if="status == 'inactive'"
        center
        :src="`${mainUrl}img/account_inactive.svg`"
        fluid
        alt="Account Inactive"
      />
      <div
        v-if="status == 'inactive'"
        class="text-center"
      >
        <span class="text-title">
          Account Inactive
        </span><br>
        <span
          v-if="userRole === 'admin'"
          class="text-content"
        >
          This profile has been deactivated. You have the option to activate this Client.
        </span>
        <span
          v-else
          class="text-content"
        >
          This profile has been deactivated.
        </span>
      </div>
      <!-- DEACTIVATE MODAL -->
      <b-modal
        v-if="status == 'active' && userRole === 'admin'"
        id="deactivateModal"
        ref="my-modal"
        title="Deactivate"
        ok-title="Deactivate"
        centered
        cancel-variant="outline-dark"
        ok-variant="secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-card-text class="mt-2">
            You are about to deactivate the client account of <b> {{ client }} </b> Select the reason for deactivation below:
          </b-card-text>
          <b-form-group
            :state="nameState"
            label=""
            class="mt-2"
            label-for="name-input"
            invalid-feedback="Other field is required"
          >
            <b-form-group label="">
              <b-form-radio-group
                v-model="deactivationReason"
                :options="options"
                class="mb-0"
                name="radios-stacked"
                stacked
              />
            </b-form-group>
            <div class="ml-2">
              <b-form-input
                v-if="deactivationReason == 'Other'"
                id="name-input"
                v-model="otherOption"
                class="mt-0"
                :state="nameState"
              />
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- OTHER EXPENSE MODAL -->
      <b-modal
        v-if="userRole === 'admin'"
        id="expenseModal"
        ref="expenses-modal"
        key="componentKey"
        title="Other Expenses"
        ok-title="Submit"
        centered
        cancel-variant="outline-dark"
        ok-variant="secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleExpenses"
      >
        <form
          ref="form"
          @submit.stop.prevent="updateOtherExpenses"
        >
          <label for="expense-amount">How much did you spent for other expenses?</label>
          <b-form-input
            id="expense-amount"
            v-model="expenses[0].expenseAmount"
            type="number"
            class="mt-0"
          />
          <label for="expense-notes">Notes</label>
          <b-form-textarea
            id="expense-notes"
            v-model="expenses[0].expenseNotes"
            placeholder="Notes"
            rows="4"
          />
        </form>
      </b-modal>
      <!-- VIEW PHOTOS MODAL -->
      <b-modal
        id="viewPhotos"
        ref="my-modal"
        centered
        :hide-footer="true"
        @show="resetModal"
      >
        <form
          ref="form"
        >
          <!-- <b-img
            class="view-photos"
            :src="`${mainUrl}api/mobile/tasks/${taskPhoto}.jpg`"
            height="300"
            width="250"
            fluid
            center
            alt="Responsive image"
          /> -->
          <!-- :src="`${mainUrl}api/mobile/tasks/${taskPhoto}.jpg`" taskPhoto -->
          <b-carousel
            id="carousel-example-generic"
            controls
            indicators
          >
            <div
              v-for="(n, index) in numSlides"
              :key="index"
            >
              <!-- :img-src="(index == 0) ? `${mainUrl}api/mobile/tasks/${taskPhoto}.jpg`: `${mainUrl}api/mobile/tasks/${taskPhoto} (${index+1}).jpg`" -->
              <b-carousel-slide
                :img-src="(index == 0) ? `${mainUrl}api/mobile/tasks/${taskPhoto}.jpg`: `${mainUrl}api/mobile/tasks/${taskPhoto} (${n}).jpg`"
              />
            </div>
          </b-carousel>
        </form>
      </b-modal>
    </div>
    <div v-if="role === 'client'">
      <div class="pageTitle">
        <h1 class="mb-1 primary-text-dark">
          Carelogs
        </h1>
      </div>
      <b-col
        cols="4"
      >
        <transition name="fade">
          <add-tasks
            v-if="addForm === true"
            id="addTaskForm"
            :shift-id="shiftId"
            :shift-data="shiftData"
            :clock-id="clockId"
          />
        </transition>
      </b-col>
      <b-col
        cols="4"
      >
        <transition name="fade">
          <add-clients
            v-if="addForm === true"
            id="addClientForm"
            form-title="Update Client"
            :client-details="clientDetails"
            :client-id="id"
          />
        </transition>
      </b-col>
      <!-- DAILY CARE LOGS -->
      <div
        v-if="show === true"
      >
        <b-card
          no-body
          class="mb-1 client-accordion"
        >
          <b-card-header
            header-tag="header"
            class="p-0"
            role="tab"
          >
            <b-col
              lg="12"
              cols="12"
              class="pt-1 pb-1 border-bottom"
            >
              <h5 class="">{{ weekdayName }}, {{ monthName }} {{ day }}
                <span>
                  <b-button
                    class="btn-calendar ml-1 p-1 gr"
                    variant="secondary"
                    size="40"
                    @click="triggerCalendar"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="15"
                      class="teal"
                    />
                  </b-button>
                  <flat-pickr
                    id="due-date"
                    ref="mycalendar"
                    v-model="dateFilter"
                    class="invisible "
                    :value="dateFilter"
                    :config="flatPickrConfig"
                    placeholder="Choose a date"
                    @input="getTasks(dateFilter)"
                  />
                </span>
              </h5>
            </b-col>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  cols="12"
                  class="mb-1"
                >
                  <div
                    class="accordion"
                  >
                    <b-card
                      v-for="(value, index) in servicesPlan"
                      :key="index"
                      no-body
                      class="mb-1 border"
                      role="tablist"
                    >
                      <b-card-header
                        header-tag="header"
                        class="p-0 task-accordion"
                        role="tab"
                        @click="getClockId(servicesPlan[servicesPlan[index][0].caregiver_id])"
                      >
                        <b-col
                          v-b-toggle="'task-'+servicesPlan[index][0].id"
                          lg="12"
                          cols="12"
                          class="pt-1 pb-1"
                          @click="taskChevron == 'ChevronDownIcon' ? taskChevron = 'ChevronUpIcon' : taskChevron = 'ChevronDownIcon'"
                        >
                          <h5>
                            <i
                              v-if="servicesPlan[index][0].shift_status === 'Completed'"
                              id="taks-check-icon"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="18"
                                class="check-circle-icon"
                              />
                            </i>
                            <i
                              v-if="servicesPlan[index][0].shift_status === 'Scheduled'"
                              id="taks-watch-icon"
                            >
                              <feather-icon
                                icon="WatchIcon"
                                size="18"
                                class="watch-circle-icon"
                              />
                            </i>
                            <i
                              v-if="servicesPlan[index][0].shift_status === 'In Progress'"
                              id="taks-watch-icon-inprogress"
                            >
                              <feather-icon
                                icon="WatchIcon"
                                size="18"
                                class="watch-circle-icon"
                              />
                            </i>
                            <span
                              id="task-caregiver"
                              class="ml-1"
                            >
                              {{ servicesPlan[index][0].time }} {{ servicesPlan[index][0].caregiver }} ({{ servicesPlan[index][0].shift_status }})
                            </span>
                            <feather-icon
                              :icon="taskChevron"
                              class="float-right"
                            />
                          </h5>
                        </b-col>
                      </b-card-header>
                      <b-collapse
                        :id="'task-'+servicesPlan[index][0].id"
                        accordion="task-accordion"
                        role="tabpanel"
                      >
                        <b-card-body class="pl-0 pr-0">
                          <b-row class="show ml-0 mr-1">
                            <b-col
                              lg="6"
                              cols="12"
                            >
                              <h4>Task List</h4>
                            </b-col>
                            <b-col
                              lg="6"
                              cols="12"
                            >
                              <p class="float-right">
                                <feather-icon
                                  icon="ClockIcon"
                                  size="14"
                                  class="text-body align-middle mr-25"
                                />
                                From {{ start_date }} {{ servicesPlan[index][0].time }} to {{ end_date }} {{ servicesPlan[index][servicesPlan[index].length-1].end_time }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row class="show">
                            <b-col
                              lg="12"
                              cols="12"
                              class="pl-0 pr-0"
                            >
                              <b-table
                                :fields="clientfields"
                                :items="servicesPlan[servicesPlan[index][0].caregiver_id]"
                                show-empty
                                empty-text="No matching records found"
                                responsive
                                class=" section-border mb-0"
                              >
                                <template #cell(status)="columndata">
                                  <b-media vertical-align="center">
                                    <feather-icon
                                      v-if="columndata.item.status === 'Completed' || columndata.item.status === 'completed'"
                                      icon="CheckIcon"
                                      size="16"
                                      class="text-body align-middle mr-25"
                                    />
                                    <feather-icon
                                      v-if="columndata.item.status === 'Not-Completed' || columndata.item.status === 'non-completed'"
                                      icon="XIcon"
                                      size="16"
                                      class="text-body align-middle mr-25"
                                    />
                                    <span
                                      v-if="columndata.item.status === 'Completed' || columndata.item.status === 'completed'"
                                      class=""
                                    >
                                      Completed
                                    </span>
                                    <span
                                      v-if="columndata.item.status === 'Not-Completed' || columndata.item.status === 'non-completed'"
                                      class=""
                                    >
                                      Not-Completed
                                    </span>
                                  </b-media>
                                </template>
                                <template #cell(time)="columndata">
                                  <p
                                    class="text-lowercase "
                                  >
                                    {{ columndata.item.time }}
                                  </p>
                                </template>
                              </b-table>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              cols="12"
                              lg="12"
                            >
                              <b-card-group
                                deck
                                class="pl-2 pr-2 pb-2 pt-2"
                              >
                                <!-- CLIENT SIGNATURE -->
                                <b-card
                                  bg-variant="primary"
                                  text-variant="dark"
                                  class="card-signature pb-2"
                                  rounded
                                >
                                  <b-row>
                                    <b-col
                                      v-if="servicesPlan[servicesPlan[index][0].caregiver_id][0].sign_client == 1"
                                      lg="6"
                                      cols="12"
                                      class="pt-2 pb-1"
                                    >
                                      <h5
                                        class=""
                                      >
                                        Client Signature
                                      </h5>
                                    </b-col>
                                    <b-col
                                      v-if="servicesPlan[servicesPlan[index][0].caregiver_id][0].sign_client == 0"
                                      lg="12"
                                      cols="12"
                                      class="pt-5 pb-0 text-center"
                                    >
                                      <b-button
                                        v-b-modal.clientSignModal
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        class="sign-button"
                                        variant="secondary"
                                      >
                                        Click here to sign (Optional)
                                      </b-button>
                                    </b-col>
                                    <!-- IMG -->
                                    <b-col
                                      v-if="servicesPlan[servicesPlan[index][0].caregiver_id][0].sign_client == 1"
                                      lg="12"
                                      cols="12"
                                      class="pt-1 pl-2"
                                    >
                                      <b-img
                                        class="daily-care-signature"
                                        :src="`${mainUrl}api/mobile/signature/sig-client-${servicesPlan[index][0].clockId}.jpg`"
                                        height="150"
                                        width="250"
                                        fluid
                                        center
                                        alt="Responsive image"
                                      />
                                      <b-card-text
                                        class="pull-left pt-2"
                                      >
                                        Signed - Electronic Signature
                                      </b-card-text>
                                      <b-card-text
                                        v-if="signedClient[servicesPlan[index][0].caregiver_id]"
                                        class="pull-left"
                                      >
                                        {{ signedClient[servicesPlan[index][0].caregiver_id][0] }}
                                      </b-card-text>
                                    </b-col>
                                  </b-row>
                                </b-card>
                                <!-- CAREGIVER SIGNATURE -->
                                <b-card
                                  v-if="servicesPlan[servicesPlan[index][0].caregiver_id][0].sign_caregiver == 1 && signedCaregiver[servicesPlan[index][0].caregiver_id]"
                                  bg-variant="primary"
                                  text-variant="dark"
                                  class="card-signature pb-0 invisible"
                                  rounded
                                >
                                  <b-row>
                                    <b-col
                                      lg="6"
                                      cols="12"
                                      class="pt-2 pb-2"
                                    >
                                      <h5
                                        class=""
                                      >
                                        Caregiver Signature
                                      </h5>
                                    </b-col>
                                    <b-col
                                      lg="12"
                                      cols="12"
                                      class="pt-1 pl-2"
                                    >
                                      <b-img
                                        class="daily-care-signature"
                                        :src="`${mainUrl}api/mobile/signature/sig-caregiver-${servicesPlan[index][0].clockId}.jpg`"
                                        height="150"
                                        width="250"
                                        fluid
                                        center
                                        alt="Responsive image"
                                      />
                                      <b-card-text
                                        class="pull-left pt-2"
                                      >
                                        Signed - Electronic Signature
                                      </b-card-text>
                                      <b-card-text
                                        v-if="signedCaregiver[servicesPlan[index][0].caregiver_id]"
                                        class="pull-left"
                                      >
                                        {{ signedCaregiver[servicesPlan[index][0].caregiver_id][0] }}
                                      </b-card-text>
                                    </b-col>
                                  </b-row>
                                </b-card>
                                <!-- EOL CAREGIVER SIGNATURE -->
                              </b-card-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <!-- CLIENT SIGN modal -->
      <b-modal
        id="clientSignModal"
        ref="sign-modal"
        key="componentKey"
        title="Sign"
        ok-title="Submit"
        centered
        cancel-variant="outline-dark"
        ok-variant="secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleClientSign"
      >
        <form
          ref="form"
          @submit.stop.prevent="signClientCarelogs"
        >
          <b-card-text class="mt-2 ">
            Please draw your signature below.
          </b-card-text>
          <vue-signature-pad
            ref="clientSignature"
            v-model="clientSignature"
            height="125px"
            :options="{onBegin: () => {$refs.clientSignature.resizeCanvas()}}"
            class="form-control"
          />
          <b-card-text class="mt-2 mb-2  text-primary-light">
            By signing, you confirm that all the information for this shift is accurate to the best of your knowledge.
          </b-card-text>
        </form>
      </b-modal>
    </div>
    <!--SERVICES AND NAVIGATE TO ADDRESS-->
    <b-row
      v-if="role === 'agent' && status !== 'inactive'"
    >
      <b-col
        md="6"
      >
        <b-card
          no-body
          class="p-0"
        >
          <b-card-body
            class="pb-0"
          >
            <b-card-title>Services</b-card-title>
            <b-card-sub-title>Services to be performed.</b-card-sub-title>
          </b-card-body>
          <b-list-group
            flush
            class="mt-1 h-50 services_list"
          >
            <b-form-checkbox-group
              v-model="carePlan.services"
              name="services-options"
            >
              <b-list-group-item
                v-for="option in carePlanOptions"
                :key="option.id"
                class="list-inline border-bottom"
              >
                <b-form-checkbox
                  :value="option.value"
                  plain
                  disabled
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-list-group-item>
            </b-form-checkbox-group>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col
        md="6"
      >
        <b-card
          no-body
          class="p-0"
        >
          <b-card-body
            class="pb-0"
          >
            <b-card-title>Daily Care Routine</b-card-title>
            <b-card-sub-title>Routinary Tasks</b-card-sub-title>
          </b-card-body>
          <b-list-group
            v-if="carePlan.routines"
            flush
            class="mt-1 h-50 services_list"
          >
            <b-list-group-item
              v-for="(routine,index) in carePlan.routines"
              :key="index"
              class="list-inline border-bottom"
            >
              {{ routine.time }} - {{ routine.description }}
            </b-list-group-item>
          </b-list-group>
          <b-list-group
            v-else
            flush
            class="mt-1 h-50 services_list"
          >
            <b-list-group-item
              class="list-inline border-bottom"
            >
              No Data To Show
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col
        md="6"
      >
        <b-card
          no-body
          class="p-0"
        >
          <b-card-body
            class="pb-0"
          >
            <b-card-title>Other Service Providers</b-card-title>
          </b-card-body>
          <b-list-group
            v-if="carePlan.other"
            flush
            class="mt-0 h-50 services_list"
          >
            <b-list-group-item
              v-for="(other,index) in carePlan.other"
              :key="index"
              class="list-inline border-bottom"
            >
              <b>{{ index }}</b>: {{ other }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col
        v-if="false"
        md="6"
      >
        <b-card
          title="Navigate to Address"
          class="p-0"
        >
          <b-card-body
            class="p-0"
          >
            <l-map
              style="height: 400px"
              :zoom="zoom"
              :center="[ client_lat, client_long ]"
            >
              <l-tile-layer
                :url="map_url"
              />
              <l-circle
                :lat-lng="[ client_lat, client_long ]"
                :radius="circle.radius"
                :color="circle.color"
              />
              <l-marker
                :lat-lng="[ client_lat, client_long ]"
              />
            </l-map>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
  <div
    v-else
    class="text-center pt-5"
  >
    <b-spinner
      style="width: 3rem; height: 3rem;"
      type="grow"
    />
    <strong class="d-block mt-2">Loading Information...</strong>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BCardTitle, BCardSubTitle, BCardText, BImg, BTab, BTabs, BButton, BFormGroup, BFormInput, BModal, VBModal, BFormRadioGroup, BTable, BCardGroup, BMedia, BDropdown, BDropdownItem, VBToggle, BCardHeader, BCollapse, BAvatar, BFormTextarea, BListGroup, BListGroupItem, BFormCheckbox, BFormCheckboxGroup, BCarousel, BCarouselSlide, BSpinner,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { onUnmounted } from '@vue/composition-api'
import { VueSignaturePad } from 'vue-signature-pad'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line
import { Icon } from 'leaflet'
// eslint-disable-next-line
import { LMap, LTileLayer, LMarker, LCircle } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import moment from 'moment'
import ClientTabs from './ClientTabs.vue'
// import Calendar from './view/CaregiverView.vue'
import AddTasks from './AddTasks.vue'
import AddClients from './AddClients.vue'
import clientStoreModule from './js/clientStoreModule'
import carePlanStoreModule from '../careplan/CarePlanStoreModule.js'
import ClientCalendar from './clientcalendar/Clientcalendar.vue'

/* eslint-disable */
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-enable */

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BButton,
    ClientTabs,
    AddTasks,
    AddClients,
    BTab,
    BTabs,
    // Calendar,
    ClientCalendar,
    flatPickr,
    BFormGroup,
    BFormInput,
    BModal,
    BFormRadioGroup,
    BTable,
    BCardText,
    BCardGroup,
    BMedia,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    /* eslint-disable */
    BCardTitle,
    BCardSubTitle,
    BCollapse,
    BAvatar,
    VueSignaturePad,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    /* eslint-enable */
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    BCarousel,
    BCarouselSlide,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    const CARE_PLAN_APP_STORE_MODULE_NAME = 'care_plan'
    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
    }
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    if (!store.hasModule(CARE_PLAN_APP_STORE_MODULE_NAME)) store.registerModule(CARE_PLAN_APP_STORE_MODULE_NAME, carePlanStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
      if (store.hasModule(CARE_PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(CARE_PLAN_APP_STORE_MODULE_NAME)
    })
    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    /* eslint-disable */
    const carePlanOptions = [
      {id: 1, text: 'Grooming (Hair, Make-up, Shaving)', value: 'grooming'},
      {id: 2, text: 'Light Housekeeping', value: 'light_housekeeping'},
      {id: 3, text: 'Bathing', value: 'bathing'},
      {id: 4, text: 'Continence', value: 'continence'},
      {id: 5, text: 'Dressing', value: 'dressing'},
      {id: 6, text: 'Eating', value: 'eating'},
      {id: 7, text: 'Toileting', value: 'toileting'},
      {id: 8, text: 'Transferring', value: 'transferring'},
      {id: 9, text: 'Lifestyle Support', value: 'liefstyle_support'},
      {id: 10, text: 'Exercise', value: 'exercise'},
      {id: 11, text: 'Meal Preparation', value: 'meal_preparation'},
      {id: 12, text: 'Medication Reminder', value: 'medical_reminder'},
      {id: 13, text: 'BP/Pulse Monitoring', value: 'bp'},
      {id: 14, text: 'Shopping/Errands', value: 'shopping'},
      {id: 15, text: 'Laundry', value: 'laundry'},
      {id: 16, text: 'Pet Care', value: 'pet_care'},
    ]

    const userData = JSON.parse(localStorage.userData)
    const userRole = userData.role
    
    /* eslint-enable */
    return {
      avatarText,
      flatPickrConfig,
      vueSignaturePadOptions,
      carePlanOptions,
      userRole,
    }
  },
  data() {
    return {
      id: this.$route.query.id,
      urlUpload: this.$urlUpload,
      data: [],
      componentKey: 0,
      userId: '',
      role: '',
      addForm: false,
      editForm: false,
      formTitle: '',
      clientSignature: '',
      caregiverSignature: '',
      name: '',
      caregiver: [],
      caregiverId: '',
      nameState: null,
      dateFilter: '',
      submittedNames: [],
      backdrop: '',
      show: true,
      mainUrl: this.$mainUrl,
      otherOption: '',
      deactivationReason: '',
      options: [
        { text: 'No longer needs care', value: 'No longer needs care' },
        { text: 'Moved to care facility', value: 'Moved to care facility' },
        { text: 'Deceased', value: 'Deceased' },
        { text: 'Other', value: 'Other' },
      ],
      start_date: '',
      start_time: '',
      end_time: '',
      end_date: '',
      pageTitle: 'Dashboard',
      client: '',
      clientInitials: '',
      emailAddress: '',
      status: '',
      address: '',
      home: '',
      location: '',
      latitude: '',
      longtitude: '',
      age: '',
      referrer: '',
      fields: [
        { key: 'time', label: 'Time', thClass: 'text-left align-middle' },
        { key: 'task', label: 'Task', thClass: 'text-left align-middle' },
        { key: 'notes', label: 'Notes', thClass: 'text-left align-middle' },
        { key: 'status', label: 'Status', thClass: 'text-left align-middle' },
        { key: 'actions', label: 'Actions', thClass: 'text-left align-middle' },
      ],
      clientfields: [
        { key: 'time', label: 'Time', thClass: 'text-left align-middle' },
        { key: 'task', label: 'Task', thClass: 'text-left align-middle' },
        { key: 'notes', label: 'Notes', thClass: 'text-left align-middle' },
        { key: 'status', label: 'Status', thClass: 'text-left align-middle' },
      ],
      expenses: [{}],
      servicesPlan: [],
      // tasksData: [],
      shiftData: [],
      clientDetails: [{}],
      familyMember: [{}],
      familyMemberSection: [{}],
      geoLocation: null,
      gettingLocation: false,
      errorStr: null,
      weekdayName: '',
      monthName: '',
      day: '',
      shiftId: '',
      familySection: 'd-none',
      clientSection: 12,
      taskId: '',
      taskDetails: '',
      clockId: '',
      carelogsChevron: 'ChevronDownIcon',
      taskChevron: 'ChevronDownIcon',
      calendarChevron: 'ChevronDownIcon',
      carePlan: [],
      map_url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      client_lat: '',
      client_long: '',
      circle: {
        radius: 4500,
        color: '#EA5455',
      },
      signedClient: [],
      signedCaregiver: [],
      taskData: {},
      taskPhoto: '',
      numSlides: 1,
      profile_picture: '',
    }
  },
  created() {
    const userData = JSON.parse(localStorage.userData)
    this.role = userData.role
    this.userId = userData.clientId
    this.componentKey = 1
    // eslint-disable-next-line
    this.getClient()
    this.getClientDetails()
    if (this.role === 'admin' || this.role === 'agent') {
      this.getFamily()
    }
    if (!('geolocation' in navigator)) {
      this.errorStr = 'Geolocation is not available.'
      return
    }
    const formatted = new Date().toJSON().slice(0, 10)
    this.dateFilter = formatted
    const date = new Date()
    const myDate = new Date(date)
    this.getTasks(this.dateFilter)
    this.weekdayName = this.getCalendarDay(myDate.getDay())
    this.monthName = this.getCalendarMonth(myDate.getMonth())
    this.day = myDate.getDate()
    // *===============================================---*
    // *--------- RETRIEVE CAREPLAN DATA -----------------*
    // *===============================================---*
    /* const fetchCarePlan = () => {
      store.dispatch('care_plan/getData', this.userId)
        .then(response => {
          // console.log('some response message', response.data.response.data)
          // const payload = response.data.response.data
          if (response.data.response.data.length !== 0) {
            response.data.response.data.forEach(data => {
              this.services = data.services
            })
          }
        })
        .catch(() => {
          // console.log('some error message', er)
        })
    }
    fetchCarePlan() */
  },
  mounted() {
    this.locateGeoLocation()
  },
  methods: {
    goToEditProfile() {
      this.$router.push({ name: 'client-profile-edit', params: {}, query: { id: this.$route.query.id } })
    },
    initMarker(loc) {
      this.existingPlace = loc
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        }
        this.locationMarkers.push({ position: marker })
        this.locPlaces.push(this.existingPlace)
        this.center = marker
        this.existingPlace = null
      }
    },
    locateGeoLocation() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        }
      })
    },
    showMsgBoxOne() {
      this.boxOne = ''
      // eslint-disable-next-line
      const messageNodes = this.$createElement(
        'div',
        {
          // eslint-disable-next-line
          domProps: { innerHTML: 'You are about to activate the client account of  <b>' + this.client + '</b>' },
        },
      )
      this.$bvModal
        .msgBoxConfirm(messageNodes, {
          title: 'Activate',
          class: '',
          size: 'sm',
          okVariant: 'secondary',
          okTitle: 'Activate',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-dark',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
          if (this.boxTwo === true) {
            store
              .dispatch('app-client/activateClient', { id: this.id })
              .then(response => {
                // eslint-disable-next-line
                const obj = response.data.response.data
                if (obj === true) {
                  this.status = 'active'
                  Swal.fire({
                    icon: 'success',
                    title: 'Activated Succesfully',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleExpenses(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.updateOtherExpenses()
    },
    handleClientSign(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.signClientCarelogs()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.submittedNames.push(this.name)
      this.$nextTick(() => {
        store
          .dispatch('app-client/deactivateClient', { id: this.id, deactivationReason: this.deactivationReason, otherOption: this.otherOption })
          .then(response => {
            // eslint-disable-next-line
            const status = response.data.response.data
            if (status === true) {
              this.status = 'inactive'
              Swal.fire({
                icon: 'success',
                title: 'Deactivated Succesfully',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    getClockId(param) {
      this.clockId = param[0].clockId
    },
    updateOtherExpenses() {
      if (!this.checkFormValidity()) {
        return
      }
      this.$nextTick(() => {
        store
          .dispatch('app-client/updateOtherExpenses', { id: this.clockId, expenses: this.expenses })
          .then(response => {
            // eslint-disable-next-line
            const data = response.data.response.data
            Swal.fire({
              icon: 'success',
              title: 'Added Succesfully',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            console.error(error)
          })
        this.$refs['expenses-modal'].toggle('#toggle-btn')
      })
    },
    addTask(shiftId, caregiverId) {
      this.editForm = true
      this.backdrop = 'b-sidebar-backdrop bg-dark'
      this.shiftId = shiftId
      this.caregiverId = caregiverId
      this.formTitle = 'Add Task'
      document.body.style.overflow = 'hidden'
    },
    editTask(taskId, task, clockId, taskData2) {
      this.taskId = taskId
      this.taskDetails = task
      this.taskData = taskData2
      this.clockId = clockId
      this.editForm = true
      this.backdrop = 'b-sidebar-backdrop bg-dark'
      this.formTitle = 'Update Task'
      document.body.style.overflow = 'hidden'
    },
    viewPhotos(image) {
      const tempImage = image.split(' ')
      if (tempImage[1]) {
        // eslint-disable-next-line
        this.taskPhoto = tempImage[0]
        // eslint-disable-next-line
        this.numSlides = parseInt(tempImage[1].replace(/\D/g, ''))
      } else {
        this.taskPhoto = image.replace('.jpg', '')
        this.numSlides = 1
      }
    },
    editClient() {
      this.editForm = true
      this.addForm = true
      this.backdrop = 'b-sidebar-backdrop bg-dark'
      // GET LATITUDE AND LONGITUDE
      this.gettingLocation = true
      navigator.geolocation.getCurrentPosition(pos => {
        this.gettingLocation = false
        this.geoLocation = pos
        this.latitude = this.geoLocation.coords.latitude
        this.longitude = this.geoLocation.coords.longitude
      }, err => {
        this.gettingLocation = false
        this.errorStr = err.message
      })
      document.body.style.overflow = 'hidden'
    },
    closeForm() {
      this.addForm = false
      this.editForm = false
      this.backdrop = ''
      document.body.style.overflow = ''
    },
    openForm() {
      this.addForm = true
      this.backdrop = 'b-sidebar-backdrop bg-dark'
    },
    getClient() {
      store
        .dispatch('app-client/getClient', { id: this.role === 'admin' || this.role === 'agent' ? this.id : this.userId })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data['clientData'][0]
          // eslint-disable-next-line
          this.client = obj.firstname + ' ' + obj.lastname
          // eslint-disable-next-line
          this.clientInitials = obj.firstname[0]
          this.emailAddress = obj.emailAddress
          this.profile_picture = obj.profile_picture
          this.referrer = obj.referrer
          this.status = obj.status
          // eslint-disable-next-line
          this.address = obj.addressLine1 + ' ' + (obj.addressLine2 || '') 
          this.home = obj.homePhone
          this.location = obj.location
          this.age = obj.age
          this.carePlan = response.data.response.data.clientData.careplan
        })
        .catch(error => {
          console.error(error)
        })
    },
    // eslint-disable-next-line
    getTasks(dateFilter2) {
      // console.log(this.dateFilter)
      const date = new Date(this.dateFilter)
      this.start_date = date.toLocaleDateString('en-US')
      this.end_date = date.toLocaleDateString('en-US')
      const myDate = new Date(date)

      if (moment().format('YYYY-MM-DD HH:mm:ss') < moment().utc().format('YYYY-MM-DD HH:mm:ss')) {
        myDate.setDate(myDate.getDate() + 1)
      }
      // console.log(myDate)
      this.weekdayName = this.getCalendarDay(myDate.getDay())
      this.monthName = this.getCalendarMonth(myDate.getMonth())
      this.day = myDate.getDate()
      this.servicesPlan = []
      // this.tasksData = []
      store
        .dispatch('app-client/getTasks', { id: this.role === 'admin' ? this.id : this.userId, dateFilter: this.dateFilter })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data
          // eslint-disable-next-line
          if (obj) {
            this.signedCaregiver = obj.signedCaregiver
            this.signedClient = obj.signedClient
            this.servicesPlan = obj.shiftsData
            // eslint-disable-next-line
            this.caregiver = obj.caregiver + " " + "(" + obj.status + ")"
          }
        })
        .catch(error => {
          console.error(error)
        })
      if (this.role === 'admin') {
        // this.filterByDate()
      }
    },
    refetchTasks() {
      const date = new Date(this.dateFilter)
      this.start_date = date.toLocaleDateString('en-US')
      this.end_date = date.toLocaleDateString('en-US')
      const myDate = new Date(date)
      if (moment().format('YYYY-MM-DD HH:mm:ss') < moment().utc().format('YYYY-MM-DD HH:mm:ss')) {
        myDate.setDate(myDate.getDate() + 1)
      }
      this.weekdayName = this.getCalendarDay(myDate.getDay())
      this.monthName = this.getCalendarMonth(myDate.getMonth())
      this.day = myDate.getDate()
      store
        .dispatch('app-client/getTasks', { id: this.role === 'admin' ? this.id : this.userId, dateFilter: this.dateFilter })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data
          // eslint-disable-next-line
          if (obj) {
            // this.tasksData = obj.tasksData
            this.servicesPlan = obj.shiftsData
            // eslint-disable-next-line
            this.caregiver = obj.caregiver + " " + "(" + obj.status + ")"
          }
        })
        .catch(error => {
          console.error(error)
        })
      if (this.role === 'admin') {
        // this.filterByDate()
      }
    },
    getCaregiverTasks() {
      const id = 55
      const clientData = JSON.stringify({
        userData: JSON.stringify({ id }),
      })
      store
        .dispatch('app-client/getCaregiverTasks', { param: clientData })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data
          // eslint-disable-next-line
          this.caregiver = obj.caregiver + " " + "(" + obj.status + ")"
        })
        .catch(error => {
          console.error(error)
        })
    },
    familyType(familyMember) {
      const family = []
      familyMember.forEach(data => {
        const formData = data
        // console.log('this', formData)
        if (familyMember) {
          family.push({
            // eslint-disable-next-line no-nested-ternary
            id: (formData.type === 'Person-In-Charge' ? 'PIC' : (formData.type === 'Power of Attorney' ? 'POA' : 'HCD')),
            name: `${`${formData.firstname} ${formData.lastname}`}`,
            homephone: formData.homephone ? formData.homephone : 'N/A',
            email: formData.email ? formData.email : 'N/A',
            relationship: formData.relationship ? formData.relationship : 'N/A',
          })
        }
      })
      // console.log('this', family)
      if (family.length > 2) family.splice(2)
      return family
    },
    getFamily() {
      store
        .dispatch('app-client/getFamily', { id: this.id })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data['familyData']
          // console.log('obj', obj)
          if (obj) {
            this.familyMember = this.familyType(obj)
            this.familySection = ''
            this.clientSection = 9
          } else {
            this.familySection = 'd-none'
            this.clientSection = 12
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    getClientDetails() {
      store
        .dispatch('app-client/getClientDetails', { id: this.role === 'admin' || this.role === 'agent' ? this.id : this.userId })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data.clientData
          this.clientDetails = obj
          this.client_lat = obj[0].latitude
          this.client_long = obj[0].longitude
          // console.log(obj)
        })
        .catch(error => {
          console.error(error)
        })
    },
    // eslint-disable-next-line
    deleteClientSignature(clockId) {
      // console.log(clockId)
      store
        .dispatch('app-client/deleteClientSignature', { id: this.clockId })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data
          Swal.fire({
            icon: 'success',
            title: 'Deleted Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.getTasks(this.dateFilter)
        })
        // eslint-disable-next-line
        .catch(error => {
          // console.error(error)
        })
    },
    // eslint-disable-next-line
    deleteCaregiverSignature(clockId) {
      // console.log(clockId)
      store
        .dispatch('app-client/deleteCaregiverSignature', { id: this.clockId })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data
          Swal.fire({
            icon: 'success',
            title: 'Deleted Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.getTasks(this.dateFilter)
        })
        .catch(error => {
          console.error(error)
        })
      // delete file
    },
    shiftCalendar() {
      this.show = !this.show
    },
    triggerCalendar() {
      this.$refs.mycalendar.fp.open()
    },
    getCalendarDay(day) {
      if (day === 0) return 'Sunday'
      if (day === 1) return 'Monday'
      if (day === 2) return 'Tuesday'
      if (day === 3) return 'Wednesday'
      if (day === 4) return 'Thursday'
      if (day === 5) return 'Friday'
      return 'Saturday'
    },
    getCalendarMonth(month) {
      if (month === 0) return 'Jan'
      if (month === 1) return 'Feb'
      if (month === 2) return 'Mar'
      if (month === 3) return 'Apr'
      if (month === 4) return 'May'
      if (month === 5) return 'Jun'
      if (month === 6) return 'Jul'
      if (month === 7) return 'Aug'
      if (month === 8) return 'Sep'
      if (month === 9) return 'Oct'
      if (month === 10) return 'Nov'
      return 'Dec'
    },
    filterByDate() {
      const tempShiftsGlobal = this.$shiftsGlobal
      tempShiftsGlobal.filter(this.getStartDate)
    },
    getStartDate(shiftData) {
      const startDate = shiftData.start.split(' ')
      if (startDate[0] === this.dateFilter) {
        this.shiftData = shiftData.shift_tasks
      }
    },
    signClientCarelogs() {
      const { isEmpty, data } = this.$refs.clientSignature.saveSignature()
      this.clientSignature = !isEmpty ? data : ''
      store
        .dispatch('app-client/signClientCarelogs', { id: this.clockId, signature: this.clientSignature })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data
          // console.log(obj)
          this.$refs['sign-modal'].toggle('#toggle-btn')
          // eslint-disable-next-line
          Swal.fire({
            icon: 'success',
            title: 'Signed Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.getTasks(this.dateFilter)
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
h1{
  margin: auto;
}
.sign-button{
  border: dotted 2px #98A1AB!important;
}
.text-primary-light{
    color: #98A1AB !important;
}
.list-group-item.px-0 {
    height: 55px;
}
#textarea-notes{
    background: #F7F9FB 0% 0% no-repeat padding-box !important;
    border: 0px solid lightgray !important;
}
.family-border{
  border: 1px solid#569099 !important;
}
.swal2-radio {
    display: block!important;
}
.signature-section{
  background: #F7F9FB!important;
}
.bg-scheduled{
  background: #01BCD6;
}
.bg-inprogress{
  background: #FFCE2D;
}
.bg-completed{
  background: #B7CF3E;
}
.bg-openshift{
  background: #E73684;
}
.bg-cancelled-client{
  background: #B1BAC3;
}
.bg-cancelled-caregiver{
  background: #FF7D01;
}
.bg-pending{
  background: #7566E8;
}
#client-collapse .card {
    border-radius: 5px!important;
}
.caregiverTasks > .card-header {
    background-color: #F4F8F9!important;
}
.section-border{
  border: 1px solid #EFEFF0;
}
.caregiverTasks  .card-body{
  padding-bottom: 0px !important;
}
.btn-calendar{
  background: #F4F8F9!important;
}
.card-signature{
  background-color: #F7F9FB!important;
}
.text-accordion{
  /* color: #33475B!important; */
  size: 15px!important;
}
.header-accordion{
  background-color: #FFFFFF;
}
.task-accordion{
  background-color: #F4F8F9!important;
}
#task-caregiver{
  font-size: 14px!important;
}
/* #client-name{
  font-size: 18px!important;
} */
.client-accordion{
  border: 1px solid #8198AD69;
}
i#taks-check-icon {
    height: 30px!important;
    width: 30px!important;
    background: #B7CF3E!important;
    padding: 5px;
    color: white;
    border-radius: 50%!important;
}
i#taks-watch-icon {
    height: 30px!important;
    width: 30px!important;
    background: #FFB92D!important;
    padding: 5px;
    color: white;
    border-radius: 50%!important;
}
i#taks-watch-icon-inprogress {
    height: 30px!important;
    width: 30px!important;
    background: #01BCD6!important;
    padding: 5px;
    color: white;
    border-radius: 50%!important;
}
.daily-care-signature{
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.date-from{
  color: #7D8D9D!important;
}
.services_list > div > div.list-group-item:nth-child(odd){
  background-color: #FAFAFC;
}
.services_list > div > div.list-group-item{
  cursor: default;
}
.leaflet-container{
  z-index: 1;
}
</style>
<style lang="scss">
  [dir] .text-center{
    font-style: normal;
    .text-title {
    text-align: center;
    font-weight: 500;
    font-size:24px !important;
    line-height: 18px;
    letter-spacing: 0px;
    opacity: 1;
  }
    [dir] .text-content {
    text-align: center;
    font-weight: normal;
    font-size:15px !important;
    line-height: 18px;
    letter-spacing: 0px;
    opacity: 1;
  }
  }
  .family-section.nav-pills .nav-link {
    padding: 0.3rem 1.5rem;
  }
</style>
