import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getClientDataAction(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')
      console.log(ctx, payload)
      return new Promise((resolve, reject) => {
        axios
        // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'client/getClientDataAction', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFamilyDataAction(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')
      console.log(ctx, payload)
      return new Promise((resolve, reject) => {
        axios
        // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'client/getFamilyDataAction', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              email: 'admin@coc.com',
              password: 'ChangeMe!',
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChecklistDataAction(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')
      console.log(ctx, payload)
      return new Promise((resolve, reject) => {
        axios
        // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'client/getChecklistDataAction', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              email: 'admin@coc.com',
              password: 'ChangeMe!',
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
