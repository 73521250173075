import { render, staticRenderFns } from "./AddClients.vue?vue&type=template&id=44ab68a5&scoped=true&"
import script from "./AddClients.vue?vue&type=script&lang=js&"
export * from "./AddClients.vue?vue&type=script&lang=js&"
import style0 from "./AddClients.vue?vue&type=style&index=0&id=44ab68a5&scoped=true&lang=css&"
import style1 from "./AddClients.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./AddClients.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ab68a5",
  null
  
)

export default component.exports