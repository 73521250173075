/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import { ref, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import Vue from 'vue'

export default function useClientCalendar() {
  onMounted(() => {
    document.querySelector('[dir] .legend .bg-light-show-all').style.filter = 'brightness(100%)'
  })
  const shifts = ref()
  const tempShifts = ref()

  /*
    API request to get all shift and tasks
    Also set to tasks store module
  */
  const getShifts = async () => {
    const formData = new FormData()
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    // console.log(JSON.stringify({ id }))
    const data = JSON.stringify({
      action: 'get-Shift',
      clientId: JSON.stringify({ id }),
    })
    formData.append('data', data)

    try {
      const response = await store.dispatch('clientcalendar/request', formData)
      // eslint-disable-next-line no-shadow
      const shifts = response.data.response.data
      shifts.forEach((shift, index) => {
        shifts[index].title = `${moment(shift.start_time, ['HH.mm']).format('ha')}-${moment(shift.end_time, ['HH.mm']).format('ha')} ${shift.caregiver == '0' ? 'Open Shift' : shift.caregiver_fullname}`
      })
      store.commit('clientcalendar/SET_SHIFTS', shifts)
      tempShifts.value = shifts
      Vue.prototype.$shiftsGlobal = shifts
    } catch (error) {
      // console.log(error)
    }
  }
  const caregiverShiftsTemp = ref([{}])
  // const caregiverShiftsTemp = ref([])
  // console.log(caregiverShiftsTemp.value)
  // console.log(caregiverShiftsTemp.value)
  // computed(() => store.state.clientcalendar.shifts)

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  // const calendarsColor = {
  //   inprogress: 'primary',
  //   completed: 'success',
  //   'wont-complete': 'danger',
  //   'not-completed': 'warning',
  //   future: 'info',
  // }
  const calendarsColor = {
    1: 'scheduled',
    2: 'in-progress',
    3: 'completed',
    4: 'm-clock-in',
    5: 'm-clock-out',
    6: 'open-shift',
    7: 'c-client',
    8: 'c-caregiver',
    9: 'tentative-not-scheduled',
    10: 'pending-confirmation',
    11: 'attention-required',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------

  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: '', // dayGridMonth,timeGridWeek,timeGridDay,listMonth
    },
    events: computed(() => store.state.clientcalendar.shifts),

    /*
      Hide time on title : false
      Diplay : true
    */
    displayEventTime: false,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: false,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 3,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: false,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent.extendedProps.status]
      // console.log(calendarEvent)

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      document.querySelector('.fc-popover') ? document.querySelector('.fc-popover').classList.add('d-none') : '' /* remove MORE popover */
      store.commit('clientcalendar/SET_ISTASKBAR', true)
      store.commit('clientcalendar/SET_SHIFT', clickedEvent)
      // console.log('event', clickedEvent)
    },
  })

  /*
    call get shift
  */
  getShifts()

  let activeFilters = []
  let showAllToggle = 0
  const filterShifts = async legendId => {
    showAllToggle = 1
    if (legendId == '1') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        console.log(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-scheduled')
        element.classList.add('outlined-1')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-scheduled')
        element.classList.remove('outlined-1')
      }
    }
    if (legendId == '2') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-in-progress')
        element.classList.add('outlined-2')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-in-progress')
        element.classList.remove('outlined-2')
      }
    }
    if (legendId == '3') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-completed')
        element.classList.add('outlined-3')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-completed')
        element.classList.remove('outlined-3')
      }
    }
    if (legendId == '6') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-open-shift')
        element.classList.add('outlined-6')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-open-shift')
        element.classList.remove('outlined-6')
      }
    }
    if (legendId == '7') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-c-client')
        element.classList.add('outlined-7')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-c-client')
        element.classList.remove('outlined-7')
      }
    }
    if (legendId == '8') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-c-caregiver')
        element.classList.add('outlined-8')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-c-caregiver')
        element.classList.remove('outlined-8')
      }
    }
    if (legendId == '10') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-pending-confirmation')
        element.classList.add('outlined-10')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-pending-confirmation')
        element.classList.remove('outlined-10')
      }
    }
    if (legendId == null) {
      // const elem = document.querySelectorAll('[dir] .legend .legend-badge')
      const showAll = document.querySelector('[dir] .legend .bg-light-show-all')
      if (!showAll.classList.contains('outlined-0')) {
        console.log('test1')
        showAllToggle = 1
        // eslint-disable-next-line no-plusplus
        const element1 = document.querySelector('[dir] .legend .bg-light-scheduled')
        element1.classList.remove('outlined-1')

        const element2 = document.querySelector('[dir] .legend .bg-light-in-progress')
        element2.classList.add('outlined-2')

        const element3 = document.querySelector('[dir] .legend .bg-light-completed')
        element3.classList.add('outlined-3')

        const element4 = document.querySelector('[dir] .legend .bg-light-open-shift')
        element4.classList.add('outlined-6')

        const element5 = document.querySelector('[dir] .legend .bg-light-c-client')
        element5.classList.add('outlined-7')

        const element6 = document.querySelector('[dir] .legend .bg-light-c-caregiver')
        element6.classList.add('outlined-8')

        const element7 = document.querySelector('[dir] .legend .bg-light-pending-confirmation')
        element7.classList.add('outlined-10')

        const element8 = document.querySelector('[dir] .legend .bg-light-show-all')
        element8.classList.add('outlined-0')
        activeFilters = []
        activeFilters = ['1']
        // document.querySelector('[dir] .legend .bg-light-scheduled').style.filter = 'brightness(100%)'
        // const element1 = document.querySelector('[dir] .legend .bg-light-scheduled')
        // element1.classList.remove('outlined-1')
      } else {
        console.log('test2')
        showAllToggle = 0
        // eslint-disable-next-line no-plusplus
        // for (let i = 0; i < elem.length; i++) {
        //   elem[i].style.filter = 'brightness(70%)'
        // }
        const element1 = document.querySelector('[dir] .legend .bg-light-scheduled')
        element1.classList.add('outlined-1')

        const element2 = document.querySelector('[dir] .legend .bg-light-in-progress')
        element2.classList.add('outlined-2')

        const element3 = document.querySelector('[dir] .legend .bg-light-completed')
        element3.classList.add('outlined-3')

        const element4 = document.querySelector('[dir] .legend .bg-light-open-shift')
        element4.classList.add('outlined-6')

        const element5 = document.querySelector('[dir] .legend .bg-light-c-client')
        element5.classList.add('outlined-7')

        const element6 = document.querySelector('[dir] .legend .bg-light-c-caregiver')
        element6.classList.add('outlined-8')

        const element7 = document.querySelector('[dir] .legend .bg-light-pending-confirmation')
        element7.classList.add('outlined-10')

        const element = document.querySelector('[dir] .legend .bg-light-show-all')
        element.classList.remove('outlined-0')
        activeFilters = []
      }
    }

    console.log(activeFilters)

    if (activeFilters.length < 7 && showAllToggle > 0) {
      console.log('testting 1')
      const element = document.querySelector('[dir] .legend .bg-light-show-all')
      element.classList.add('outlined-0')
    } else {
      console.log('testting 2')
      const element = document.querySelector('[dir] .legend .bg-light-show-all')
      element.classList.remove('outlined-0')
    }
    caregiverShiftsTemp.value = await tempShifts.value.filter(shift => {
      let keep = true
      console.log(shift.status)
      if (showAllToggle > 0) {
        keep = keep && activeFilters.includes(shift.status.toString())
      }
      // keep = keep && f.status == legendId
      return keep
    })

    store.commit('clientcalendar/SET_SHIFTS', caregiverShiftsTemp.value)
    console.log(caregiverShiftsTemp.value)
  }

  return {
    // variables
    shifts,
    tempShifts,
    caregiverShiftsTemp,
    calendarOptions,

    // methods
    getShifts,
    filterShifts,
  }
}
