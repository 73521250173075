/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-empty */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import Vue from 'vue'
import axios from '@axios'
import moment from 'moment'

export default {

  namespaced: true,
  state: {
    shifts: [],
    shift: null,
    tasksRaw: [],
    tasks: [],
    isTaskBar: false,
    calendarOptions: [
      {
        id: null,
        class: 'show-all',
        label: 'Show All',
      },
      {
        id: '1',
        class: 'scheduled',
        label: 'Scheduled',
      },
      {
        id: '2',
        class: 'in-progress',
        label: 'In Progress',
      },
      {
        id: '3',
        class: 'completed',
        label: 'Completed',
      },
      // {
      //   id: '4',
      //   class: 'm-clock-in',
      //   label: 'Missed Clock-in',
      // },
      // {
      //   id: '5',
      //   class: 'm-clock-out',
      //   label: 'Missed Clock-out',
      // },
      {
        id: '6',
        class: 'open-shift',
        label: 'Open Shift',
      },
      {
        id: '7',
        class: 'c-client',
        label: 'Cancelled by Client',
      },
      {
        id: '8',
        class: 'c-caregiver',
        label: 'Cancelled by Caregiver',
      },
      // {
      //   id: '9',
      //   class: 'tentative-not-echeduled',
      //   label: 'Tentative/Not Scheduled',
      // },
      {
        id: '10',
        class: 'pending-confirmation',
        label: 'Pending Confirmation',
      },
      // {
      //   id: '11',
      //   class: 'attention-required',
      //   label: 'Attention Required',
      // },
    ],
  },
  getters: {
  },
  mutations: {
    SET_TASKS_RAW: (state, payload) => state.tasksRaw = payload,
    SET_TASKS: (state, payload) => state.tasks = payload,
    SET_SHIFTS: (state, payload) => state.shifts = payload,
    SET_SHIFT: (state, payload) => state.shift = payload,
    APPEND_TASKS: (state, payload) => {
      payload.forEach(task => state.tasks.push(task))
    },
    SET_ISTASKBAR: (state, payload) => state.isTaskBar = payload,
  },
  actions: {
    request: (state, payload) => new Promise((resolve, reject) => {
      axios
        // eslint-disable-next-line no-undef
        .post(`${Vue.prototype.$apiUrl2}clientCalendar/action`, payload,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
        .then(response => resolve(response))
        .catch(error => reject(error))
    }),

    /*
      Get all shift and task and formatted for Calendar use
    */
    formatTasks: (state, payload) => {
      // console.log('payload', payload)

      const tempTasks = []

      payload.forEach(shift => {
        const { shift_tasks } = shift
        if (shift_tasks) {
          // eslint-disable-next-line no-unused-expressions
          shift_tasks.forEach(task => {
            // console.log('task',task)
            const title = task.description ? task.description.replace(/<\/?[^>]+(>|$)/g, '') : task.task
            task.status = task.status === '' ? 'inprogress' : task.status
            tempTasks
              .push({
                id: task.id,
                title: `${shift.caregiver_fullname}: ${title}`,
                shiftId: task.shift_id,
                start: task.start_date ? task.start_date : shift.start,
                end: task.end_date ? task.end_date : shift.end,
                extendedProps: {
                  calendar: task.status ? task.status : 'future',
                },
              })
          })
        }
      })
      // console.log('tempTasks', tempTasks)
      state.commit('SET_TASKS', tempTasks) /* set tasks */
      state.commit('SET_TASKS_RAW', tempTasks) /* set raw task for filter use */
      // state.dispatch('fetchRecurrence', payload) /* fetch all recurence */
    },
  },
}
