<template>

  <b-card
    no-body
  >
    <div class="m-1">
      <b-row>
        <b-col
          cols="9"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3 class="pr-1">
            Clients
          </h3>
          <b-button
            v-if="userRole === 'admin'"
            id="addClientBtn"
            variant="primary"
            @click.prevent="openForm()"
          >
            <feather-icon
              icon="UserPlusIcon"
              size="16"
              class="mx-1"
            />
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-if="userRole === 'admin'"
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select pr-1"
              placeholder="Select Status"
              @input="filterByClient($event)"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>

            <!-- Input Search -->
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                v-model="searchQuery"
                type="search"
                placeholder="Find Client"
                @input="filterBySearch($event)"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refClientListTable"
      hover
      :items="list"
      responsive
      :fields="tableColumns"
      show-empty
      empty-text="No matching records found"
      class="position-relative clients_table"
      :per-page="perPage"
      :current-page="currentTablePage"
      @sort-changed="handleSortChange($event.sortDesc)"
      @row-clicked="(item, index, event) => (userRole == 'admin') ? $router.push({ name: 'client-profile', params: { action: 'edit' }, query: { id: item.id } }) : $router.push({ name: 'caregiver-client-profile', params: {},query: { id: item.id } })"
    >

      <!-- Column: Id -->
      <!-- Column: Client -->

      <!-- Name  -->
      <template #cell(client)="data">
        <div class="text-nowrap">
          <b-avatar
            size="42"
            :src="`${urlUpload}clients/${data.item.profile_picture}`"
            :text="avatarText(`${data.item.firstname.slice(0,1)} ${data.item.lastname.slice(0,1)}`)"
            :variant="`${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
            rounded
          />
          <span
            class="ml-1 text-primary"
            @click="(userRole == 'admin') ? $router.push({ name: 'client-profile', params: { action: 'edit' }, query: { id: data.item.id } }) : $router.push({ name: 'caregiver-client-profile', params: {},query: { id: data.item.id } })"
          >
            {{ data.item.firstname }}  {{ data.item.lastname }}
          </span>
        </div>
      </template>
      <!-- <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              v-if="data.item.profile_picture === ''"
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.firstname)"
              :variant="`${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
            />
            <b-img
              v-if="data.item.profile_picture !== ''"
              :src="`${urlUpload}clients/${data.item.profile_picture}`"
              height="32"
              width="32"
              rounded
            />
          </template>
          <span
            class="font-weight-bold d-block text-nowrap cursor-pointer align-self-center text-primary"
            @click="(userRole == 'admin') ? $router.push({ name: 'client-profile', params: { action: 'edit' }, query: { id: data.item.id } }) : $router.push({ name: 'caregiver-client-profile', params: {},query: { id: data.item.id } })"
          >
            {{ data.item.firstname }}  {{ data.item.lastname }}
          </span>
        </b-media>
      </template> -->
      <template #cell(contact)="data">
        <b-media vertical-align="center">
          <span class=" d-block text-nowrap">
            {{ data.item.homePhone }} <br> {{ data.item.mobilePhone }}
          </span>
        </b-media>
      </template>
      <template #cell(location)="data">
        <b-media vertical-align="center">
          <span class=" d-block text-nowrap">
            {{ data.item.addressLine1 }} <br> {{ data.item.addressLine2 }}
          </span>
        </b-media>
      </template>
      <template #cell(status)="data">
        <b-media vertical-align="center">
          <span class=" d-block text-nowrap">
            <b-badge
              v-if="data.item.status === 'active'"
              class="active"
              variant="light-dark  mr-1"
            >
              Active
            </b-badge>
            <b-badge
              v-if="data.item.status === 'inactive'"
              class="inactive"
              variant="light-dark  mr-1"
            >
              Inactive
            </b-badge>
          </span>
        </b-media>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              @click="$router.push({ name: 'client-profile', params: { action: 'edit' }, query: { id: data.item.id } })"
            >
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status === 'inactive'"
              @click="activateForm(data.item.id,data.item.firstname,data.item.lastname)"
            >
              <span>Activate</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status === 'active'"
              v-b-modal.deactivateModal
              @click="setClientId(data.item.id,data.item.firstname,data.item.lastname)"
            >
              <span>Deactivate</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>

    </b-table>
    <div class="mx-1 mb-1">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ (currentTablePage-1)*6+1 }} to <span v-if="currentTablePage < totalClientList/6">{{ currentTablePage*6 }}</span> <span v-if="currentTablePage > totalClientList/6">{{ totalClientList }}</span> of {{ totalClientList }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentTablePage"
            :total-rows="totalClientList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <!-- modal -->
    <b-modal
      v-if="deactivateModal === true"
      id="deactivateModal"
      ref="my-modal"
      title="Deactivate"
      ok-title="Deactivate"
      centered
      cancel-variant="outline-dark"
      ok-variant="danger"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk()"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <p class="mt-2 text-primary-dark">
          You are about to deactivate the client account of <b> {{ client }} </b> Select the reason for deactivation below:
        </p>
        <b-form-group
          :state="nameState"
          label=""
          class="mt-2"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-group label="">
            <b-form-radio-group
              v-model="deactivationReason"
              :options="options"
              class="text-primary-dark"
              size="14"
              name="radios-stacked"
              stacked
            />
          </b-form-group>
          <div class="ml-2">
            <b-form-input
              v-if="deactivationReason == 'Other'"
              id="name-input"
              v-model="otherOption"
              size="14"
              class="mt-0 text-primary-dark"
              :state="nameState"
            />
          </div>
        </b-form-group>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BButton, BTable, BMedia, BAvatar,BBadge, BPagination, BDropdown, BDropdownItem, BFormGroup, BModal, VBModal, BFormRadioGroup, BImg,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import { sortCompare } from '@/@fake-db/utils'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useClientLists from './js/useClientList'
import { ref, watch, computed } from '@vue/composition-api'

import clientStoreModule from './js/clientStoreModule'

export default {
   data() {
    return {
      clientData: this.list,
      currentTablePage: this.currentClientPage,
      urlUpload: this.$urlUpload,
      name: '',
      nameState: null,
      submittedNames: [],
      options: [
        { text: 'No longer needs care', value: 'No longer needs care' },
        { text: 'Moved to care facility', value: 'Moved to care facility' },
        { text: 'Deceased', value: 'Deceased' },
        { text: 'Other', value: 'Other' },
      ],
      otherOption: '',
      deactivationReason: '',
      status: '',
      clientData: '',
      deactivateModal: false,
      client: '',
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    vSelect,
    Swal,
    sortCompare,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormGroup,
    BModal,
    VBModal,
    BFormRadioGroup,
    BImg,
  },
  props: {
    list: {
        type: Array,
        required: true,
    },
    totalClientList: {
      type: Number,
      required: true,
    },
    currentClientPage: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'All Clients',
      'Active Clients',
      'Inactive Clients',
    ]
    // Table Handlers
    const tableColumns = [
      { key: 'client', sortable: true },
      { key: 'contact', sortable: false },
      { key: 'location', sortable: false },
      { key: 'last_visited', sortable: false },
      { key: 'status', sortable: false },
    ]
    
    if (props.userRole == 'admin')
      tableColumns.push({ key: 'actions' })

    const {
      dataMeta,
      perPageOptions,
      refClientListTable,

      resolveClientAvatarVariant,
    } = useClientLists(props)

  const perPage = 6
  const totalClients = props.totalClientList
  const currentPage = props.currentClientPage
  const searchQuery = ref('')
  const sortBy = 'id'
  const isSortDirDesc = true
  const statusFilter = ref(null)
  

  const refetchData = () => 
  { // console.log('refetchdata')
    // console.log(props.value)
    props.refresh()
  }

//
    // return all set value above
    return {
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      statusFilter,
      refetchData,
      tableColumns,
      statusOptions,
      // toPage,
      avatarText,
      resolveClientAvatarVariant,
    }

  },
  created(){
    var client = this.$route.fullPath.replaceAll('%20',' ');
    client = client.split('?');
    var clientName = client[1];
    if(clientName !=null){
      this.filterBySearch(clientName)
      this.searchQuery = clientName;
    }
          //this.filterBySearch(clientName)
          //this.searchQuery = clientName;
  },
  methods: {
    openForm() {
      this.$parent.openForm('add','')
    },
    updateForm(id) {
      // console.log(id)
      this.$parent.openForm('update',id)
      this.$parent.fetchClientDetails(id)
    },
    removeClient(id) {
       this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$parent.removeClient(id)
        }
      })
      
    },
    filterByClient(option){
      // console.log(option)
      // console.log(this.list)
      this.$parent.filterByClient(option)
    },
    checkStatus(list){
      if(list.status == 'inactive'){
        return list
      }
    },
    filterBySearch(search){
      this.$parent.filterBySearch(search)
    },
    handleSortChange(event) {
      // console.log(event)
      const sortedData = this.list.sort(sortCompare('client'))
      const sortDesc = !false
      if (sortDesc) sortedData.reverse()
      this.clientData = sortedData
    },
    activateForm(id,firstname,lastname) {
      this.clientId = id
      this.boxOne = ''
      // console.log(id)
      // eslint-disable-next-line
      this.client = firstname + ' ' + lastname
      // eslint-disable-next-line
      // this.message = 'You are about to activate the client account of ' + this.client
      const messageNodes = this.$createElement(
        'div',
        {
          // eslint-disable-next-line
          domProps: { innerHTML: 'You are about to activate the client account of  <b>' + this.client + '</b>' },
        },
      )
      this.$bvModal
        .msgBoxConfirm(messageNodes, {
          title: 'Activate',
          size: 'sm',
          okVariant: 'secondary',
          okTitle: 'Activate',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-dark',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
            if (this.boxTwo === true) {
              store
                .dispatch('app-client/activateClient', { id: this.clientId })
                .then(response => {
                  // eslint-disable-next-line
                  const obj = response.data.response.data
                  // console.log(obj)
                  if (obj === true) {
                    this.status = 'active'
                    Swal.fire({
                      icon: 'success',
                      title: 'Activated Succesfully',
                      showConfirmButton: false,
                      timer: 1500,
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                    this.$parent.getClientData()
                  }
                })
                .catch(error => {
                  console.error(error)
                })
            }
        })
    },
    setClientId(id,firstname,lastname){
      this.clientId = id
      // eslint-disable-next-line
      this.client = firstname + ' ' + lastname
      this.deactivateModal = true
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk() {
      // Prevent modal from closing
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      // this.$nextTick(() => {
        store
          .dispatch('app-client/deactivateClient', { id: this.clientId, deactivationReason: this.deactivationReason, otherOption: this.otherOption })
          .then(response => {
            // eslint-disable-next-line
            const status = response.data.response.data
            this.deactivateModal = false
            if (status === true) {
              this.status = 'inactive'
              Swal.fire({
                icon: 'success',
                title: 'Deactivated Succesfully',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$parent.getClientData()
            }
          })
          .catch(error => {
            console.error(error)
          })
        this.$refs['my-modal'].toggle('#toggle-btn')
      // })
    },
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
th:nth-child(2) {
    width: 183px;
}
.active{
  background: #E0F7FA !important;
  color: #01BCD6 !important;
  font: normal normal bold 12px/10px Montserrat !important;
}
.inactive{
  background: #DCE4EE !important;
  color: #516F90 !important;
  font: normal normal bold 12px/10px Montserrat !important;
}
div#deactivateModal .custom-radio {
    margin-bottom: 7px!important;
}
.clients_table .media{
  align-items: center !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
