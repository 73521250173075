<!-- eslint-disable -->
<template>
  <div>
    <div
      :class="backdrop"
      style="z-index:1032;"
    />
    <div class="row breadcrumbs-top">
      <!-- <div class="pageTitle">
           <h1>{{ pageTitle }} <span class="text-black-50"> |</span></h1>
      </div> -->
      <div class="col-12">
        <h2 class="content-header-title float-left pr-1 mb-0"> {{ pageTitle }} </h2>
        <div class="breadcrumb-wrapper">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a
                :href="pageTitle === 'My Clients' ? '/caregiverDashboard' : '/'"
                class="router-link-active"
                target="_self"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="align-text-top feather feather-home"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                  <polyline points="9 22 9 12 15 12 15 22" />
                </svg>
              </a>
            </li>
            <li class="breadcrumb-item">
              <a
                href="#"
                target="_self"
                class="text-primary"
              > {{ pageTitle }} </a>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <b-row class="d-flex flex-row pl-0 pt-2">
      <b-col
        id="tableColumnForm"
        :cols="tableColumn"
      >
        <client-list
          v-if="this.showTable == 'true'"
          ref="clientList"
          :list="this.clientData"
          :total-client-list="this.totalClientList"
          :current-client-page="this.currentClientPage"
          :user-role="userRole"
        />
      </b-col>
      <b-col
        :cols="addFormColumn"
      >
        <transition name="fade">
          <add-clients
            v-if="this.showAddForm == 'true'"
            id="addClientForm"
            ref="addClient"
            :key="componentKey"
            :class="showForm"
            :client-id="clientId"
            :client-details="this.clientDetails"
            :form-title="this.formTitle"
          />
        </transition>
      </b-col>
    </b-row>
    
  </div>
</template>

<script>
/* eslint-disable */
// import JQuery from 'jquery'

import { BRow, BCol,BTabs, BTab, BCardText } from 'bootstrap-vue'
import AddClients from './AddClients.vue'
import ClientList from './ClientList.vue'
import Profile from './Profile.vue'
import Preferences from './Preferences.vue'
import clientsStoreModule from '../../apps/clients/clientsStoreModule'
import Swal from 'sweetalert2'
import store from '@/store'

export default {
  components: {
    AddClients,
    ClientList,
    Profile,
    Preferences,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    clientsStoreModule,
  },
  data() {
    return {
      tableColumn: '12',
      addFormColumn: '4',
      showForm: 'hidden',
      backdrop: '',
      showAddForm: false,
      componentKey: 0,
      clientData: [],
      clientDataStatic:[],
      clientDetails:[],
      totalClientList: 0,
      showTable: 'false',
      currentClientPage: 1,
      clientStatus: '',
      formTitle: 'Add Client',
      clientId: '',
    }
  },
  created() {

      let user = JSON.parse(localStorage.getItem('userData'))
      const formdata = {
        uid: user.id,// localStorage.getItem('userId'),
        employee: localStorage.getItem('fullName'),
        role: this.userRole,
      }

      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/getClientDataAction', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        this.clientData = response.data.clientData
        this.clientDataStatic = response.data.clientData
        this.totalClientList = this.clientDataStatic.length
        this.showTable = 'true'
      }, response => {
        //Destroy session
        if(response.status !== 200) {
          this.$router.push({ name: 'login' })
        }
      })
       .catch(error =>  {
            console.log(error.response.status)
        })
  },
  setup() {
    let pageTitle = ''
    const user = JSON.parse(localStorage.getItem('userData'))
    const userRole = user.role
      if(userRole === 'agent') {
        pageTitle = 'My Clients'
      }
      else {
        pageTitle = 'Clients'
      }

    return {
      userRole,
      pageTitle,
    }
  },
  methods: {
    captureUserActivity() {
      const formdata = {
        page: 'client',
        uid: localStorage.getItem('userId'),
        activity: 'view',
        employee: localStorage.getItem('fullName'),
        page_url: 'clients',
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/captureUserActivity2', {
        name: 'captureUserActivity2',
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        console.log(response.data)
      })
    },
    openForm(param,id) {
      if(param == 'add'){
        this.tableColumn = '12'
        this.addFormColumn = '4';
        this.showForm = 'visible'
        this.showAddForm = 'true'
        this.backdrop = 'b-sidebar-backdrop bg-dark'
        this.formTitle = "Add Client"
      }
      if(param == 'update'){
        this.tableColumn = '12'
        this.addFormColumn = '4';
        this.showForm = 'visible'
        this.showAddForm = 'true'
        this.backdrop = 'b-sidebar-backdrop bg-dark'
        this.formTitle = "Update Client"
        // this.viewClient(id)
      }
    },
    closeForm() {
      this.tableColumn = '12'
      this.showAddForm = 'false'
      this.backdrop = ''
    },
    viewClient(id){
       const formdata = {
        clientId: id,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/viewClientAction', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
      })
    },
    rerenderForm() {
      this.componentKey += 1
      this.showForm = 'visible'
    },
    filterByClient(option){
      if(option == 'Inactive Clients'){
        this.clientData =  this.clientDataStatic.filter(this.checkStatusInactive)  
      }
      else if(option == 'Active Clients'){
        this.clientData =  this.clientDataStatic.filter(this.checkStatusActive)
      }
      else{
        this.clientData =  this.clientDataStatic.filter(this.checkStatusAll)
      }
      this.clientStatus = option
    },
    filterBySearch(search){
      this.currentClientPage = 1
      this.clientData = this.clientDataStatic
      this.clientData = this.getMatchingResults(search)
      this.totalClientList = this.clientData.length
      if(this.clientStatus == 'Inactive Clients'){
        this.clientData =  this.clientData.filter(this.checkStatusInactive)  
      }
      else if(this.totalClientList == 'Active Clients'){
        this.clientData =  this.clientData.filter(this.checkStatusActive)
      }
      else{
        this.clientData =  this.clientData.filter(this.checkStatusAll)
      }

    },
    getMatchingResults(search) {
       return this.clientDataStatic.filter(
        client =>
          (
            client.name.toLowerCase().includes(search.toLowerCase())) 
      )
    },
    checkStatusInactive(list){
      if(list.status == 'inactive'){
        return list
      }
    },
    checkStatusActive(list){
      if(list.status == 'active'){
        return list
      }
    },
    checkStatusAll(list){
        return list
    },
    removeClient(id){
       const formdata = {
        clientId: id,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/removeClientDataAction', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        // eslint-disable-next-line
          Swal.fire({
          icon: 'success',
          title: 'Removed Succesfully',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.getClientData()
      })
    },
    handleSortChange(){
   
    },
    getClientData(){
      const formdata = {
        page: 'client',
        uid: localStorage.getItem('userId'),
        activity: 'view',
        employee: localStorage.getItem('fullName'),
        page_url: 'clients',
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/getClientDataAction', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        this.clientData = response.data.clientData
        this.clientDataStatic = response.data.clientData
        this.totalClientList = this.clientDataStatic.length
      })
    },
    fetchClientDetails(id) {
      this.clientId = id
      let clientDetails = this.clientData.filter(this.getById)  
      this.clientDetails = clientDetails
    },
    getById(list){
      if(list.id == this.clientId) {
        return list
      }
    },
  },
}
</script>

<style>
h1{
  margin: auto;
}
.fade-enter-from{
  opacity: 0;
}
.fade-enter-to{
  opacity: 1;
}
.fade-leave-active{
  transition: all 0.3s ease;
}
.fade-leave-from{
  opacity: 1;
}
.fade-leave-to{
  opacity: 0;
}
.fade-leave-active{
  transition: all 0.3s ease;
}
.pageTitle{
  display: inline-block;
}
/* .breadcrumb-wrapper{
  display: inline-block;
  margin-top: 4px;
} */
#deactivateModal .custom-radio {
    margin-bottom: 7px!important;
}
</style>
