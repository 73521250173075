import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientLists(list) {
  // Use toast
  const toast = useToast()

  const refClientListTable = ref(null)

  const perPage = ref(4)
  const totalClients = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  let clientData = []
  clientData = list

  const dataMeta = computed(() => {
    const localItemsCount = clientData.list.value ? clientData.list.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    }
  })

  const refetchData = () => {
    clientData.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchClients = (ctx, callback) => {
    store
      .dispatch('fetchClients', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      // eslint-disable-next-line no-unused-vars
      .then(response => {
        callback(clientData.list)
        totalClients.value = 23
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveClientAvatarVariant = status => {
    if (status === 'All Clients') return 'primary'
    if (status === 'Active Clients') return 'primary'
    if (status === 'Inactive Clients') return 'dark'
    return 'primary'
  }

  return {
    fetchClients,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClientListTable,

    statusFilter,

    resolveClientAvatarVariant,

    refetchData,
  }
}
