<!-- eslint-disable -->
<template>
  <section
    v-if="this.addForm == true"
    id="client-basic-input"
  >
    <validation-observer ref="simpleRules">
      <b-form
        v-if="formTitle === 'Add Task'"
        class="easein"
      >
        <b-row>
          <b-col md="12">
            <div class="card">
              <div class="card-header form d-flex flex-row ">
                <b-col class="pl-0 ">
                  <p class="card-title">
                    Add Task 
                  </p>
                </b-col>
                <b-col class="pr-0">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click.prevent="closeForm()"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </b-col>
              </div>
              <div class="card-body add-form">
                <b-row>
                  <b-col
                    v-for="(item,index) in this.tasksFields"
                    :key="index"
                    lg="12"
                    class="mb-0"
                  >
                    <!-- <div 
                      v-if="item.type == 'checkbox' && shiftData !== null"
                      class="mb-1"
                    >
                      <p>Select Task/s to Add</p>
                      <b-form-radio
                        v-for="(value, index2) in shiftData"
                        :key="index2"
                        class="mb-1"
                        name="shift-radios"
                        :value="shiftData[index2].task"
                        @change="selectTask(shiftData[index2])"
                      >
                      {{ shiftData[index2].task }}
                    </b-form-radio>
                    </div> -->
                    <!-- INPUT FOR TEXT TYPE -->
                    <b-form-group
                      v-if="item.type == 'text' && item.required == true"
                      :label="item.label"
                      :label-for="item.bind"
                      class="mb-0 pb-0 mt-0 pt-0"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.label"
                        rules="required"
                      >
                        <b-form-input
                          id="item.bind"
                          v-model="fields[item.bind]"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="item.label"
                          lg="12"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-if="item.type == 'text' && item.required == false"
                      :label="item.label"
                      :label-for="item.bind"
                      class="mb-0 pb-0 mt-0 pt-0"
                    >
                      <b-form-input
                        id="item.bind"
                        v-model="fields[item.bind]"
                        :placeholder="item.label"
                        lg="12"
                      />
                    </b-form-group>
                    <!-- INPUT FOR SELECT -->
                    <b-form-group
                      v-if="item.type == 'select' && item.bind=='status'"
                      :label="item.label"
                      :label-for="item.bind"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.label"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                          class="mb-1"
                        >
                         <v-select
                            v-model="fields['status']"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :options="status"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- INPUT FOR QUILL EDITOR -->
                    <div
                      v-if="item.type == 'textarea'"
                      class="mb-1"
                    >
                      <label
                        class="form-label"
                        for="helperText"
                      >{{ item.label }}</label>
                      <div
                        v-if="item.toolbar == 'quillEditorCustom'"
                        class="message-editor"
                      >
                        <quill-editor
                          id="quil-content"
                          v-model="fields[item.bind]"
                          :options="editorOption"
                          class="border-bottom-0"
                        />
                        <div
                          id="quill-toolbar"
                          class="d-flex justify-content-end border-top-0"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold" />
                          <button class="ql-italic" />
                          <button class="ql-underline" />
                          <button class="ql-align" />
                          <button class="ql-link" />
                        </div>
                      </div>
                      <div
                        v-if="item.toolbar == 'quillEditorCustom2'"
                        class="message-editor"
                      >
                        <quill-editor
                          id="quil-content2"
                          v-model="fields[item.bind]"
                          :options="editorOption2"
                          class="border-bottom-0"
                        />
                        <div
                          id="quill-toolbar2"
                          class="d-flex justify-content-end border-top-0"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold" />
                          <button class="ql-italic" />
                          <button class="ql-underline" />
                          <button class="ql-align" />
                          <button class="ql-link" />
                        </div>
                      </div>
                    </div>
                    <!-- INPUT FOR DATE -->
                    <!-- INPUT FOR TIME -->
                      <label
                        v-if="item.type == 'time'"
                        for="timepicker-valid"
                      >
                        Start Time
                      </label>
                      <br>
                      <b-form-timepicker
                        v-if="item.type == 'time'"
                        id="datepicker-valid"
                        v-model="fields[item.bind]"
                        :state="true"
                        @input="timePicker(fields[item.bind])"
                        class="mb-1"
                      />
                  </b-col>
                  <b-col lg="12" class="mb-2 mt-2">
                    <b-button
                      variant="secondary"
                      type="submit"
                      class="mr-1"
                      @click.prevent="saveTasks"
                    >
                      Save
                    </b-button>
                    <b-button
                      variant="outline-dark"
                      type="cancel"
                      @click.prevent="closeForm"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <b-form class="easein" v-if="formTitle === 'Update Task'">
        <b-row>
          <b-col md="12">
            <div class="card">
              <div class="card-header form d-flex flex-row ">
                <b-col class="pl-0 ">
                  <p class="card-title">
                    {{ taskDetails }}
                  </p>
                </b-col>
                <b-col class="pr-0">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click.prevent="closeForm()"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </b-col>
              </div>
              <div class="card-body add-form">
                <b-row>
                  <b-col
                    lg="12"
                    class="mb-0"
                  >
                    <!-- INPUT FOR RADIO -->
                    <label for="task-completed">
                    <p class="text-primary-dark mb-0"> Have you completed this task? </p>
                    </label>
                    <div 
                      id="task-completed"
                      class="mb-1 demo-inline-spacing"
                    >
                      <b-form-radio
                        class="mb-1"
                        v-model="editFields.status"
                        name="completed-radios"
                        value="Completed"
                      >
                        Yes
                      </b-form-radio>
                      <b-form-radio
                        class="mb-1"
                        v-model="editFields.status"
                        name="completed-radios"
                        value="Not-Completed"
                      >
                        No
                      </b-form-radio>
                    </div>
                    <!-- INPUT FOR TEXT AREA DETAILS -->
                      <label for="task-notes" class="text-primary-dark">Notes</label>
                      <b-form-textarea
                        id="task-notes"
                        v-model="editFields.notes"
                        class="mb-1"
                        placeholder="Add Notes here"
                        rows="4"
                      />
                    <!-- MILEAGE -->
                    <div class="card card-mileage p-1" rounded>
                      <!-- INPUT FOR RADIO -->
                      <label for="transportation" class="text-primary-dark">Form of transportation</label>
                      <div 
                        id="transportation"
                        class="mb-1 demo-inline-spacing"
                      >
                        <b-form-radio
                          v-model="editFields.mileage_transpo"
                          class="mb-1"
                          name="transportation-radios"
                          value="Commuting"
                        >
                          Commuting
                        </b-form-radio>
                        <b-form-radio
                          v-model="editFields.mileage_transpo"
                          class="mb-1"
                          name="transportation-radios"
                          value="Driving"
                        >
                          Driving
                        </b-form-radio>
                      </div>
                      <label for="traveled-miles" class="text-primary-dark">How many miles did you travel?</label>
                       <b-form-input
                          id="traveled-miles"
                          v-model="editFields.mileage"
                          type="number"
                          lg="12"
                          class="mb-1"
                        />
                        <label for="mileage-notes" class="text-primary-dark">Notes</label>
                        <b-form-textarea
                          id="mileage-notes"
                          v-model="editFields.mileage_notes"
                          class="mb-1"
                          placeholder="Add Notes here"
                          rows="4"
                        />
                    </div>
                    <b-form-file
                        v-model="editFields.filePhoto"
                        type="file"
                        class="d-none"
                        ref="uploadPhoto"
                        accept="image/png, image/gif, image/jpeg"
                        capture
                        @change="loadFile($event)"
                      />
                    <label for="upload-photos" class="text-primary-dark">Upload Photos</label>
                    <div
                      id="upload-photos"
                      class=""
                      @click="triggerFileInput"
                    >
                      <b-avatar
                        class="upload-photos mb-3 cursor-pointer mr-1 "
                        size="107"
                        variant="light-secondary"
                        rounded
                      >
                        <feather-icon 
                          size="45"
                          class="camera-icon"
                          icon="CameraIcon" 
                        />
                      </b-avatar>
                      <b-avatar
                        v-if="editFields.preview_url !== ''"
                        class=" uploaded-photos mb-3 cursor-pointer"
                        size="107"
                        variant="light-secondary"
                        rounded
                      >
                        <b-img
                          id="photo-preview"
                          size="45"
                          :src="editFields.preview_url"
                        />
                      </b-avatar>
                      
                    </div>
                    <!-- UPLOAD PHOTOS -->
                  <!-- ACTION BUTTONS -->
                  </b-col>
                  <b-col lg="12" class="mb-0">
                    <b-button
                      variant="secondary"
                      type="submit"
                      class="mr-1"
                      @click.prevent="editTasks"
                    >
                      Save
                    </b-button>
                    <b-button
                      variant="outline-dark"
                      type="cancel"
                      @click.prevent="closeForm"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </section>
</template>
<script>
/* eslint-disable */
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BFormTimepicker, BFormRadio, BFormTextarea, BAvatar, BFormFile, BImg,
} from 'bootstrap-vue'
import clientStoreModule from './js/clientStoreModule'
import { required } from '@validations'
import { TheMask } from 'vue-the-mask'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    quillEditor,
    TheMask,
    BFormTimepicker,
    clientStoreModule,
    BFormRadio,
    BFormTextarea,
    BAvatar,
    BFormFile,
    BImg,
  },
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: false,
    },
    shiftId: {
      type: String,
      required: false,
    },
    caregiverId: {
      type: String,
      required: false,
    },
    clockId: {
      type: String,
      required: false,
    },
    dateFilter: {
      type: String,
      required: false,
    },
    taskId: {
      type: String,
      required: false,
    },
    taskDetails: {
      type: String,
      required: false,
    },
    taskData: {
      type: Object,
      required: false,
    },
    shiftData: {
      type: Array,
      required: false,
    },
    quilEditor1: {
      type: String,
      required: false,
    },
    quilEditor2: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      pageTitle: 'Clients',
      addForm: true,
      hidden: 'hidden1',
      name: '',
      required,
      id: this.$route.query.id,
      componentKey: 0,
      fields: {
        start_time: '',
        task: '',
        details: '',
        description: '',
        status: '',
        added_by: '',
        shift_id: '',
        caregiver_id: '',
        date_filter: '',
      },
      editFields: {
        status: '',
        notes: '',
        mileage: '',
        mileage_transpo: '',
        mileage_notes: '',
        task_id: '',
        files: [],
        filePhoto: [],
        clock_id: '',
        preview_url: '',
      },
      status: [
        { title: 'Scheduled' },
        { title: 'In Progress' },
        { title: 'Completed' },
      ],
      displayPhoto: 'invisible',
      filePhoto: [],
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your description',
    }

   const CLIENT_APP_STORE_MODULE_NAME = 'app-client-add'

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })
    return {
      // UI
      editorOption,
    }
  },
  created() {
    this.editFields.task_id = this.taskId
    this.editFields.clock_id = this.clockId
    this.fields.shift_id = this.shiftId
    this.fields.caregiver_id = this.caregiverId
    this.fields.date_Filter = this.dateFilter
    this.getTaskDetails(this.taskId)
  },
  updated() {
    this.editFields.task_id = this.taskId
    this.editFields.clock_id = this.clockId
    this.fields.shift_id = this.shiftId
    this.fields.caregiver_id = this.caregiverId
    this.fields.date_Filter = this.dateFilter
  },
  computed: {
    tasksFields() {
      return [
        {
          id: '', class: '', label: 'Select Task/s to Add', type: 'checkbox', value: '', placeholder: '', bind: '', required: true,
        },
        {
          id: '', class: '', label: 'Task *', type: 'text', value: '', placeholder: '', bind: 'task', required: true,
        },
        {
          id: '', class: '', label: 'Details', type: 'text', value: '', placeholder: '', bind: 'details', required: false,
        },
        {
          id: '', class: '', label: 'Start Time', type: 'time', value: '', placeholder: '', bind: 'start_time', required: true,
        },
        {
          id: '', class: '', label: 'Status', type: 'select', value: '', placeholder: '', bind: 'status', required: true,
        },
      ]
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            alert('add')
        }
      })
    },
    timePicker(param) {
      console.log(param)
    },
    loadFile (e) {
      // this.editFields.filePhoto = event.target.files[0]
      if(e.target.files.length > 0){
        this.editFields.preview_url = URL.createObjectURL(e.target.files[0])
        let preview = document.getElementById("photo-preview")
        preview.src = src
        preview.style.display = "block"
      }
    },
    closeForm() {
      this.$parent.closeForm()
    },
    saveTasks() {
        store
        .dispatch('app-client-add/saveTasks', { id: this.shiftId, task: this.fields })
        .then(response => {
          // eslint-disable-next-line
          Swal.fire({
            icon: 'success',
            title: 'Added Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.closeForm()
          this.$parent.refetchTasks()
        })
        .catch(error => {
          console.error(error)
        })
    },
    getTaskDetails(taskId) {
      store
        .dispatch('app-client-add/getTaskDetails', {id: taskId})
        .then(response => {
          // eslint-disable-next-line
          const taskDetails = response.data.response.data.taskDetails[0]
          console.log(taskDetails)
          this.editFields.status = taskDetails.status
          this.editFields.notes = taskDetails.notes
          this.editFields.mileage = taskDetails.mileage
          this.editFields.mileage_transpo = taskDetails.mileage_transpo
          this.editFields.mileage_notes = taskDetails.mileage_notes
        })
        .catch(error => {
          console.error(error)
        })
    },
    editTasks() {
        store
        .dispatch('app-client-add/updateTasks', this.editFields)
        .then(response => {
          // eslint-disable-next-line
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.closeForm()
          this.$parent.refetchTasks()
        })
        .catch(error => {
          console.error(error)
        })
    },
    selectTask(shifts){
      this.fields.task = shifts.task
      this.fields.details = shifts.details
      this.fields.status= shifts.status
      const start_time = shifts.start_time.split(' ')
      this.fields.start_time = start_time[1]
    },
    triggerFileInput(){
      this.$refs.uploadPhoto.$el.childNodes[0].click()
    },
  },
}
</script>

<style scoped>
@import './css/task.css';
</style>
<style>
.ql-container.ql-snow {
    border-bottom: none!important;
}
div#quill-toolbar,#quill-toolbar2 {
    border-radius: 0px!important;
    border-bottom-right-radius: 2px!important;
    border-bottom-left-radius: 2px!important;
}
.due-time > div{
    margin-left: 0px !important
}
.b-time .b-time-header output {
    width: 150px!important;
}
.card-mileage{
  background-color: #F7F9FB!important;
}
.camera-icon{
  color: #7A8CAE!important;
}
.upload-photos{
  border: dotted 3px #B1BAC4;
}
.upload-photos:hover{
  opacity: 0.8;
}
.text-primary-dark{
  color: #33475B!important;
}
img#photo-preview {
  height: 107px;
  width: 107px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
