<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="$store.state.clientcalendar.isTaskBar"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $store.commit('clientcalendar/SET_ISTASKBAR', val)"
    >
      <template #default="{}">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            <span>Shift Details</span>

          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="$store.commit('clientcalendar/SET_ISTASKBAR', false)"
            />
          </div>
        </div>

        <!-- Body -->

        <!-- Tasks Form -->
        <b-form
          class="p-2"
        >
          <div>
            <b-form-group v-if="$store.state.clientcalendar.shift">
              <!-- {{ $store.state.clientcalendar.shift.extendedProps }} -->
              <!-- shift details -->
              <div>
                <!-- client -->
                <div class="d-flex mt-1">
                  <span
                    v-if="$store.state.clientcalendar.shift.extendedProps.client_fullname"
                    class="mr-1"
                  >
                    <b-avatar
                      size="34"
                      :src="$store.state.clientcalendar.shift.extendedProps.clientPicture ? `${url}upload/clients/${store.state.clientcalendar.shift.extendedProps.clientPicture}` : ''"
                      variant="primary"
                      :text="`${$store.state.clientcalendar.shift.extendedProps.client_fullname ? returnFullname($store.state.clientcalendar.shift.extendedProps.client_fullname) : ''}`"
                    />
                  </span>
                  <span>
                    <span class="info-primary">
                      {{ `${$store.state.clientcalendar.shift.extendedProps.client_fullname.split(', ')[1]} ${$store.state.clientcalendar.shift.extendedProps.client_fullname.split(', ')[0]}` }}
                    </span><br>
                    <span class="text-muted info-secondary">
                      Client
                    </span>
                  </span>
                </div>
                <!-- caregiver -->
                <div class="d-flex mt-1">
                  <span
                    v-if="$store.state.clientcalendar.shift.extendedProps.caregiver_fullname"
                    class="mr-1"
                  >
                    <!-- {{ $store.state.clientcalendar.shift.extendedProps }} -->
                    <!-- <b-avatar
                      size="34"
                      :src="$store.state.clientcalendar.shift.extendedProps.caregiverPicture ? `${url}upload/${$store.state.clientcalendar.shift.extendedProps.caregiver}/${$store.state.clientcalendar.shift.extendedProps.caregiverPicture}` : ''"
                      variant="primary"
                      :text="`${$store.state.clientcalendar.shift.extendedProps.caregiver_firstame.charAt(0)}${$store.state.clientcalendar.shift.extendedProps.caregiver_lastname.charAt(0)}`"
                    /> -->
                    <b-avatar
                      size="34"
                      :src="$store.state.clientcalendar.shift.extendedProps.caregiverPicture ? `${url}upload/${$store.state.clientcalendar.shift.extendedProps.caregiverPicture}` : ''"
                      variant="primary"
                      :text="`${$store.state.clientcalendar.shift.extendedProps.caregiver_fullname ? returnFullname($store.state.clientcalendar.shift.extendedProps.caregiver_fullname) : ''}`"
                    />
                  </span>
                  <span>
                    <span class="info-primary">
                      {{ ($store.state.clientcalendar.shift.extendedProps.status != '6' || $store.state.clientcalendar.shift.extendedProps.status != 6) ? `${$store.state.clientcalendar.shift.extendedProps.caregiver_fullname.split(', ')[1]} ${$store.state.clientcalendar.shift.extendedProps.caregiver_lastname}` : 'Open Shift' }}
                    </span><br>
                    <span class="text-muted info-secondary">
                      {{ ($store.state.clientcalendar.shift.extendedProps.status != '6' || $store.state.clientcalendar.shift.extendedProps.status != 6) ? 'Caregiver' : 'No Caregiver Assigned' }}
                    </span>
                  </span></div>
                <!-- start date -->
                <div class="d-flex mt-1">
                  <span class="mr-1 f-icon-container">
                    <span class="m-auto">
                      <feather-icon
                        icon="CalendarIcon"
                        size="25"
                        class="f-icon"
                      />
                    </span>
                  </span>
                  <span>
                    <span class="info-primary">
                      {{ moment($store.state.clientcalendar.shift.start).format('ddd, MMMM DD, YYYY') }}
                    </span><br>
                    <span class="text-muted info-secondary">
                      {{ `${moment($store.state.clientcalendar.shift.extendedProps.start_time, ["HH.mm"]).format("hh:mm a")} - ${moment($store.state.clientcalendar.shift.extendedProps.end_time, ["HH.mm"]).format("hh:mm a")}` }}
                    </span>
                  </span>
                </div>
                <!-- location -->
                <div class="d-flex mt-1">
                  <span class="mr-1 f-icon-container">
                    <span class="m-auto">
                      <feather-icon
                        icon="MapPinIcon"
                        size="25"
                        class="f-icon"
                      />
                    </span>
                  </span>
                  <span class="my-auto">
                    <span
                      v-if="$store.state.clientcalendar.shift.extendedProps.location === ''"
                      class="info-primary"
                    >
                      N/A
                    </span>
                    <span
                      v-else
                      class="info-primary"
                    >
                      {{ $store.state.clientcalendar.shift.extendedProps.location }}
                    </span>
                  </span>
                </div>
              </div>
              <!-- task/s and shift tasks/s  -->
              <b-list-group
                v-if="$store.state.clientcalendar.shift.extendedProps.shift_tasks.length > 0"
                flush
                class="mt-2"
              >
                <h4 v-if="$store.state.clientcalendar.shift.extendedProps.status === '1'">
                  Shift Task/s
                </h4>
                <h4 v-else>
                  Task/s
                </h4>
                <b-list-group-item
                  v-for="(taskOption, index) in $store.state.clientcalendar.shift.extendedProps.shift_tasks"
                  :key="taskOption.id"
                  class="text-truncate"
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      <span class="mr-1">{{ index + 1 }}.</span>
                      <span v-if="taskOption.description">
                        {{ stripTags(taskOption.description) }}
                      </span>
                      <span v-else>{{ taskOption.task }}</span>
                    </div>
                    <div
                      v-if="$store.state.clientcalendar.shift.extendedProps.status !== '1' && taskOption.status"
                    >
                      <b-badge :variant="taskStatus[taskOption.status]">
                        {{ taskOption.status }}
                      </b-badge>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <b-list-group
                v-else
                class="no-data-text mt-2"
              >
                <span v-if="$store.state.clientcalendar.shift.extendedProps.status === '1'">
                  No Shift Task
                </span>
                <span v-else>
                  No Task
                </span>
              </b-list-group>
              <!-- <b-button
                v-if="$store.state.clientcalendar.shift.extendedProps.status === '1'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-cancel-shift
                class="mt-3"
                variant="secondary"
              >
                Cancel Shift
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-3"
                variant="secondary"
                @click="$store.commit('clientcalendar/SET_ISTASKBAR', false)"
              >
                OK
              </b-button>

            </b-form-group>
          </div>
        </b-form>
      </template>
    </b-sidebar>
    <!-- warning cancel modal  -->
    <b-modal
      id="modal-cancel-shift"
      title="Cancel Shift"
      centered
    >
      <div class="d-flex mt-1">
        <feather-icon
          class="m-auto text-warning"
          icon="AlertCircleIcon"
          size="85"
        />
      </div>
      <div class="warning-popup text-center mt-1">
        <span class="warning-primary">
          ARE YOU SURE?
        </span><br>
        <span class="warning-secondary">
          Do you want cancel this shift?
        </span>
      </div>
      <!-- caregiver -->
      <!-- <div class="d-flex">
        <span class="mr-1">
          <b-avatar
            size="34"
            :src="$store.state.clientcalendar.shift.extendedProps.caregiverPicture ? `${url}upload/${$store.state.clientcalendar.shift.extendedProps.caregiver}/${$store.state.clientcalendar.shift.extendedProps.caregiverPicture}` : ''"
            variant="primary"
            :text="`${$store.state.clientcalendar.shift.extendedProps.caregiver_firstame.charAt(0)}${$store.state.clientcalendar.shift.extendedProps.caregiver_lastname.charAt(0)}`"
          />
        </span>
        <span>
          <span class="info-primary">
            {{ `${$store.state.clientcalendar.shift.extendedProps.caregiver_firstame} ${$store.state.clientcalendar.shift.extendedProps.caregiver_lastname}` }}
          </span><br>
          <span class="text-muted info-secondary">
            Caregiver
          </span>
        </span>
      </div> -->
      <!-- start date -->
      <!-- <div class="d-flex mt-1">
        <span class="mr-1 f-icon-container">
          <span class="m-auto">
            <feather-icon
              icon="CalendarIcon"
              size="25"
              class="f-icon"
            />
          </span>
        </span>
        <span>
          <span class="info-primary">
            {{ moment($store.state.clientcalendar.shift.start).format('ddd, MMMM DD, YYYY') }}
          </span><br>
          <span class="text-muted info-secondary">
            {{ `${moment($store.state.clientcalendar.shift.extendedProps.start_time, ["HH.mm"]).format("hh:mm a")} - ${moment($store.state.clientcalendar.shift.extendedProps.end_time, ["HH.mm"]).format("hh:mm a")}` }}
          </span>
        </span>
      </div> -->
      <!-- location -->
      <!-- <div class="d-flex mt-1">
        <span class="mr-1 f-icon-container">
          <span class="m-auto">
            <feather-icon
              icon="MapPinIcon"
              size="25"
              class="f-icon"
            />
          </span>
        </span>
        <span class="my-auto">
          <span
            v-if="$store.state.clientcalendar.shift.extendedProps.location === ''"
            class="info-primary"
          >
            N/A
          </span>
          <span
            v-else
            class="info-primary"
          >
            {{ $store.state.clientcalendar.shift.extendedProps.location }}
          </span>
        </span>
      </div> -->
      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          @click="ok()"
        >
          Continue
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BAvatar,
  BButton,
  BModal,
  BBadge,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import moment from 'moment'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BButton,
    BModal,
    BBadge,
  },
  directives: {
    Ripple,
  },
  setup() {
    const eventLocal = ref({})
    const taskOptions = ref(computed(store.state.clientcalendar.tasks))

    const returnFullname = name => {
      // console.log(name)
      // eslint-disable-next-line eqeqeq
      if (typeof name === 'string') {
        let initials = ''
        const nameArray = name.split(/[ ,]+/)

        if (nameArray.length >= 2) {
          const firstName = nameArray[0]
          const lastName = nameArray[1]

          // eslint-disable-next-line no-undef
          initials = lastName[0].toUpperCase() + firstName[0].toUpperCase()
        }

        return initials
      }

      return ''
    }
    return {
      eventLocal,
      taskOptions,
      stripTags: html => {
        const tmp = document.implementation.createHTMLDocument('New').body
        tmp.innerHTML = html
        return tmp.textContent || tmp.innerText || ''
      },
      moment,
      url: Vue.prototype.$mainUrl,
      taskStatus: {
        inprogress: 'primary',
        completed: 'success',
        'wont-complete': 'danger',
        'not-completed': 'warning',
        future: 'info',
      },
      returnFullname,
    }
  },
}
</script>
